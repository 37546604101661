import createSelectors from '@/store/selectors';
import { deepCopy } from '@/services/global/globalUtils';
import type {
    OverlayDescription,
    OverlayStore,
    OverlayType,
} from '@/types/global/types';
import { create } from 'zustand';

const INITIAL_OVERLAY_STORE: OverlayStore = {
    currentOverlayIndex: 0,
    descriptions: [] as OverlayDescription[],
    overlayType: 'neutral',
};

const useOverlayStoreBase = create(() => deepCopy(INITIAL_OVERLAY_STORE));

export const useOverlayStore = createSelectors(useOverlayStoreBase);

export const setCurrentOverlayIndex = (index: number): void => {
    useOverlayStoreBase.setState({
        currentOverlayIndex: index,
    });
};

export const setCurrentOverlayDescriptions = (
    descriptions: OverlayDescription[],
    type: OverlayType
): void => {
    useOverlayStoreBase.setState({
        descriptions,
        overlayType: type,
        currentOverlayIndex: 0,
    });
};

export const setCurrentOverlayType = (type: OverlayType): void => {
    useOverlayStoreBase.setState({
        overlayType: type,
    });
};

export const resetOverlays = (): void => {
    useOverlayStoreBase.setState(deepCopy(INITIAL_OVERLAY_STORE));
};
