// datas

// utils
import { handleItemSelected } from '@/services/device/deviceHandleFunctions';
import { SHAPES, type ShapesTetris } from '@/services/global/types';

// services
import { ClickTetris } from '@/assets/sounds/soundsEffect';
import styles from '@/features/devices/tablets/components/game3/chooseTetris.module.scss';
import Shape from '@/features/devices/tv/components/games/game3/shape/Shape';
import { playSound } from '@/services/global/globalHandleFunctions';
import { useGameStore } from '@/store/gameStore';
import type { GameItem } from '@/types/games/types';

interface ChooseTetrisProps {
    character: 'eric' | 'fanny' | 'paul';
}
const ChooseTetris: React.FC<ChooseTetrisProps> = ({ character }) => {
    const gameStatus = useGameStore.use.gameStatus();

    const handleSelectTetris = (item: GameItem) => {
        handleItemSelected(item, `tetris${character}`, gameStatus);
        playSound(ClickTetris);
    };

    return (
        <div className={styles.chooseTetrisContainer}>
            {Object.keys(SHAPES).map((tetris, blockIndex) => {
                const shape = SHAPES[tetris as keyof ShapesTetris].shape.filter(
                    (row) => row.some((cell) => cell)
                );
                return (
                    <Shape
                        key={blockIndex}
                        shape={shape}
                        blockIndex={blockIndex}
                        block={tetris}
                        onClick={() => {
                            handleSelectTetris(tetris);
                        }}
                        character={character}
                        isUpcoming={false}
                    />
                );
            })}
        </div>
    );
};

export default ChooseTetris;
