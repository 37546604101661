import { HeaderTop } from '@/assets/images/tablets';
import styles from '@/features/devices/tv/components/HUD/headerTv.module.scss';
import { useGameStore } from '@/store/gameStore';
import { motion } from 'framer-motion';

import dataGlobal from '@/services/datas/global.json';
import { useGame5Store } from '@/store/games/game5Store';
import { useTranslation } from 'react-i18next';
import { GAMESTATUS } from '@/types/games/types';

const variants = {
    // Slide for top to its position
    top: {
        y: -100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.5,
        },
    },
};

const HeaderTv: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const { t } = useTranslation(['global', 'actionContent']);

    const currentTitle = t(`${currentGameStatus}.tv.headerContent.title`, {
        ns: 'actionContent',
    });

    const isGame5 = currentGameStatus === GAMESTATUS.GAME5ACTION;
    const currentIndex = useGame5Store.use.currentIndex();
    const currentSituation = dataGlobal.GAME5ACTION.situations[currentIndex];

    return (
        <motion.div
            className={styles.headerTvContainer}
            variants={variants}
            initial="top"
            animate="visible"
        >
            <img src={HeaderTop} alt="pattern" />
            <div>
                <p>
                    {currentTitle} {isGame5 && currentSituation.id}
                </p>
            </div>
        </motion.div>
    );
};

export default HeaderTv;
