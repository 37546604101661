import headerDatas from '@/services/datas/actionContent.json';
import data from '@/services/datas/global.json';
import { deepCopy } from '@/services/global/globalUtils';
import { type Game1Store } from '@/types/games/game1/types';
import {
    GAMESTATUS,
    type Emotion,
    type Item,
    type _Symbol,
} from '@/types/games/types';
import { create } from 'zustand';
import createSelectors from '../selectors';

export interface ItemSelected<TAction> {
    item: Item;
    itemKey: keyof TAction;
}

export const INITIAL_GAME1_STORE: Game1Store = {
    itemsSelected: { action1: null, action2: null },
    itemsFound: Array(data.emotions.length).fill(null),
    headerContent: headerDatas[GAMESTATUS.GAME1ACTION1].tv.headerContent,
};
export interface GameOneAction1 {
    emotion: Emotion;
    symbol: _Symbol;
}

const useGame1StoreBase = create<Game1Store>(() => deepCopy(INITIAL_GAME1_STORE));

export const useGame1Store = createSelectors(useGame1StoreBase);

export const setItemsSelectedGame1 = <
    TActionKey extends keyof Game1Store['itemsSelected'],
    TAction extends Game1Store['itemsSelected'][TActionKey],
>(
    action: TActionKey,
    itemSelected: ItemSelected<TAction>
): void => {
    const { item, itemKey } = itemSelected;
    useGame1StoreBase.setState((state) => ({
        itemsSelected: {
            ...state.itemsSelected,
            [action]: {
                ...state.itemsSelected[action],
                [itemKey]: item,
            },
        },
    }));
};

export const setItemsFoundGame1 = (emotion: Emotion): void => {
    const newItemsFound = deepCopy(useGame1StoreBase.getState().itemsFound);
    newItemsFound.splice(emotion.id - 1, 1, emotion);

    useGame1StoreBase.setState({
        itemsFound: newItemsFound,
    });
};

export const selectItemsSelectedGame1 = <
    TAction extends keyof Game1Store['itemsSelected'],
    TOutput extends Game1Store['itemsSelected'][TAction],
>(
    action: TAction
): TOutput => {
    return useGame1StoreBase.getState().itemsSelected[action] as TOutput;
};

export const selectItemsFoundGame1 = <TItem = Item>(): Array<TItem> => {
    return useGame1StoreBase.getState().itemsFound as Array<TItem>;
};

export const resetItemsSelectedGame1 = (): void => {
    useGame1StoreBase.setState({
        itemsSelected: deepCopy(INITIAL_GAME1_STORE.itemsSelected),
    });
};

export const resetItemsFoundGame1 = (): void => {
    useGame1StoreBase.setState({
        itemsFound: deepCopy(INITIAL_GAME1_STORE.itemsFound),
    });
};
