import styles from '@/features/devices/tv/components/games/game3/Game3Action.module.scss';
import { useGame3Store } from '@/store/games/game3Store';
import { type Block } from '@/services/global/types';

interface CellProps {
    type: Block;
    rowIndex: number;
    colIndex: number;
}

const Cell: React.FC<CellProps> = ({ type, colIndex, rowIndex }: CellProps) => {
    const itemsSelected = useGame3Store.use.itemsSelected();

    return (
        <div
            className={`${styles.cell} ${
                styles[
                    `row-${rowIndex}-col-${colIndex}-emotion-${itemsSelected?.emotion?.id}`
                ]
            } ${`${styles[`${type.tetris}`]}`} ${`${styles[`${type.character}`]}`}`}
        />
    );
};

export default Cell;
