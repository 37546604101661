import { Boop } from '@/assets/sounds/soundsEffect';
import styles from '@/features/devices/tablets/components/itemBox/itemBoxTablet.module.scss';
import { useGameStore } from '@/store/gameStore';
import { motion } from 'framer-motion';

import { playSound } from '@/services/global/globalHandleFunctions';
import {} from '@/services/global/types';
import { useItemsFound } from '@/services/hooks/useItemFounds';
import type { GameStatusActionWithoutGame5, Item } from '@/types/games/types';

interface ItemBoxTabletProps {
    item: Item;
    handleClick: () => void;
    showName?: boolean;
    isSymbol?: boolean;
    isSelected?: boolean;
    itemsFound?: Item[];
}

const ItemBoxTablet: React.FC<ItemBoxTabletProps> = ({
    item,
    handleClick,
    showName,
    isSymbol,
    isSelected,
}) => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame5
    );

    const isItemFound = itemsFound?.find((itemFound) => itemFound?.id === item?.id);

    return (
        <motion.button
            whileTap={{ scale: 0.8 }}
            className={`${styles.itemBox} ${
                isSelected && !isSymbol && styles[`${item.slug}-selected`]
            } ${isSelected && isSymbol && styles['symbol-selected']}
                    ${isSymbol && styles.symbol}`}
            onClick={() => {
                item && handleClick();
                playSound(Boop);
            }}
            disabled={!showName && isItemFound !== undefined}
        >
            <img src={item?.image ?? ''} alt="" />
            {showName && item !== null && 'title' in item && <p>{item?.title}</p>}
        </motion.button>
    );
};

export default ItemBoxTablet;
