import type { Behavior, HeaderContent } from '../types';

export interface Game4Store {
    itemsSelected: Game4Action;
    itemsFound: Behavior[];
    listBehaviors: Behavior[];
    headerContent: HeaderContent;
    carouselValues: CarouselValues;
}

export interface Game4Action {
    behavior: Behavior;
    code: string;
}

export interface CarouselValues {
    [key: string]: number;
}

export interface Carousel {
    key: 'rightIndex' | 'centerIndex' | 'leftIndex' | 'flowDirection';
    value: number;
}

export const INITIAL_GAME4_STORE: Game4Store = {
    itemsSelected: {} as Game4Action,
    listBehaviors: [] as Behavior[],
    itemsFound: Array(4).fill(null),
    headerContent: {} as HeaderContent,
    carouselValues: {
        flowDirection: 0,
        centerIndex: 0,
        leftIndex: 0,
        rightIndex: 1,
    },
};
