import FooterTv from '@/features/devices/tv/components/HUD/FooterTv';
import HeaderTv from '@/features/devices/tv/components/HUD/HeaderTv';
import HeaderTvRecap from '@/features/devices/tv/components/HUD/HeaderTvRecap';
import { useGameStore } from '@/store/gameStore';

import { GAMESTATUS } from '@/types/games/types';

interface LayoutProps {
    children: React.ReactNode;
}
const Layout: React.FC<LayoutProps> = ({ children }) => {
    const isGame5 = useGameStore.use.gameStatus() === GAMESTATUS.GAME5ACTION;
    return (
        <>
            <HeaderTv />
            {!isGame5 && <HeaderTvRecap />}
            {children}
            {!isGame5 && <FooterTv />}
        </>
    );
};

export default Layout;
