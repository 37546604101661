import { useGame1Store } from '@/store/games/game1Store';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGame4Store } from '@/store/games/game4Store';
import { useMemo } from 'react';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame5,
    type ItemsSelected,
} from '@/types/games/types';

export const useItemsSelected = (
    gameStatus: GameStatusActionWithoutGame5
): ItemsSelected => {
    const itemsSelectedGame1Action1 = useGame1Store.use.itemsSelected().action1;
    const itemsSelectedGame1Action2 = useGame1Store.use.itemsSelected().action2;
    const itemsSelectedGame2 = useGame2Store.use.itemsSelected();
    const itemsSelectedGame3 = useGame3Store.use.itemsSelected();
    const itemsSelectedGame4 = useGame4Store.use.itemsSelected();

    const itemsSelected = useMemo(
        () => ({
            [GAMESTATUS.GAME1ACTION1]: itemsSelectedGame1Action1 as ItemsSelected,
            [GAMESTATUS.GAME1ACTION2]: itemsSelectedGame1Action2 as ItemsSelected,
            [GAMESTATUS.GAME2ACTION]: itemsSelectedGame2 as ItemsSelected,
            [GAMESTATUS.GAME3ACTION]: itemsSelectedGame3 as ItemsSelected,
            [GAMESTATUS.GAME4ACTION]: itemsSelectedGame4 as ItemsSelected,
        }),
        [
            itemsSelectedGame1Action1,
            itemsSelectedGame1Action2,
            itemsSelectedGame2,
            itemsSelectedGame3,
            itemsSelectedGame4,
        ]
    );
    return itemsSelected[gameStatus];
};
