import SocketService from '@/services/SocketService';
import { useRoomStore } from '@/store/roomStore';
import type { ClientEvents } from '@/types/socket/types';

export const isDevelopperMode = import.meta.env.VITE_DEVELOPPER_MODE === 'true';

export function deepCopy<T>(item: T): T {
    return JSON.parse(JSON.stringify(item));
}

/**
 * Emits a socket event with the given name and data, with the room name.
 * @param {keyof ClientEvents} eventName - The name of the event.
 * @param {Omit<Parameters<ClientEvents[EventKey]>[number], 'roomName'>} data - The data to send depending on the event.
 */
export function emitSocketEvent<EventKey extends keyof ClientEvents>(
    eventName: EventKey,
    data: Omit<Parameters<ClientEvents[EventKey]>[number], 'roomName'>
): void {
    const socket = SocketService.getInstance().getSocket();
    const roomName = useRoomStore.getState().roomName;

    if (!socket) return;
    if (roomName === '') {
        // eslint-disable-next-line no-console
        console.info(`Room name was empty calling ${eventName}.`);
        return;
    }
    const params = (data ? [{ ...data, roomName }] : [{ roomName }]) as Parameters<
        ClientEvents[EventKey]
    >;
    socket.emit(eventName, ...params);
}

/**
 * Replace the {@link Object.keys} function to be typed.
 * @param obj - The object to get the keys from.
 * @returns The keys of the object.
 */
export const objectKeys = <T extends object>(obj: T): Array<keyof T> => {
    return Object.keys(obj) as Array<keyof T>;
};
