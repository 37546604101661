import type { Emotion, HeaderContent, Item, Stimulus } from '../types';

export interface Game2Store {
    isConfirmSelectionButton: boolean;
    itemsSelected: Game2Action;
    itemsFound: Item[];
    headerContent: HeaderContent;
}

export interface Game2Action {
    emotion: Emotion;
    work: Stimulus;
    family: Stimulus;
    sport: Stimulus;
    friends: Stimulus;
}

export interface Environments {
    [key: string]: {
        title: string;
        stimuli: Stimulus[];
    };
}
export interface EnvironmentsKey {
    work: boolean;
    family: boolean;
    friends: boolean;
    sport: boolean;
}

export const INITIAL_GAME2_STORE: Game2Store = {
    isConfirmSelectionButton: false,
    itemsSelected: {
        emotion: {} as Emotion,
        work: {} as Stimulus,
        friends: {} as Stimulus,
        family: {} as Stimulus,
        sport: {} as Stimulus,
    },
    itemsFound: Array(4).fill(null),
    headerContent: {} as HeaderContent,
};
