import { setItemsSelectedGame1, type ItemSelected } from '@/store/games/game1Store';
import { setItemsSelectedGame2 } from '@/store/games/game2Store';
import { setItemsSelectedGame3 } from '@/store/games/game3Store';
import { setItemsSelectedGame4 } from '@/store/games/game4Store';
import { setItemsSelectedGame5 } from '@/store/games/game5Store';
import type { GameOneAction1, GameOneAction2 } from '@/types/games/game1/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setItemsSelectedGame1Action1 = (item: ItemSelected<GameOneAction1>): void =>
    setItemsSelectedGame1('action1', item);
const setItemsSelectedGame1Action2 = (item: ItemSelected<GameOneAction2>): void =>
    setItemsSelectedGame1('action2', item);

const dynamicFunctions: Record<string, (...args: any) => void> = {
    setItemsSelectedGame1Action1,
    setItemsSelectedGame1Action2,
    setItemsSelectedGame2,
    setItemsSelectedGame3,
    setItemsSelectedGame4,
    setItemsSelectedGame5,
};

export default dynamicFunctions;
