import { Scientist } from '@/assets/images/characters';
import { Eye } from '@/assets/images/tv';

import styles from '@/features/devices/tv/components/HUD/footerTv.module.scss';
import overlatStyles from '@/features/devices/tv/components/overlay/overlay.module.scss';
import TextWritter from '@/features/reusablecomponents/text/TextWriter';
import { useGameStore } from '@/store/gameStore';

import { useTranslation } from 'react-i18next';

const FooterTv: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const { t } = useTranslation('actionContent');

    const currentTitle = t(`${currentGameStatus}.tv.footerContent.title`);
    const currentText = t(`${currentGameStatus}.tv.footerContent.text`);

    return (
        <div className={styles.footerTvContainer}>
            <img src={Scientist} alt="Scientifique" />
            <div className={overlatStyles.textContainer}>
                <div>
                    <img src={Eye} alt="oeil" />
                    <h1>{currentTitle}</h1>
                </div>
                <TextWritter text={currentText} />
            </div>
        </div>
    );
};

export default FooterTv;
