// assets
import { PatternHorizontalHud } from '@/assets/images/tv';

// scss
import styles from '@/features/devices/tv/components/HUD/headerTvRecap.module.scss';
import { useGameStore } from '@/store/gameStore';
import { useItemsFound } from '@/services/hooks/useItemFounds';
import type { GameStatusActionWithoutGame5 } from '@/types/games/types';

const HeaderTvRecap: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const itemsFound = useItemsFound(gameStatus as GameStatusActionWithoutGame5);

    return (
        <div className={styles.headerTvRecapContainer}>
            <img
                src={PatternHorizontalHud}
                alt="hud horizontal bar"
                className={styles.patternTop}
            />
            <div className={styles.listContainer}>
                {itemsFound.map((recapElement, index) => {
                    return (
                        <div key={index} className={styles.squaresContainer}>
                            <div>
                                <img
                                    src={
                                        recapElement !== null
                                            ? recapElement.image
                                            : ''
                                    }
                                    alt=""
                                />
                            </div>
                            <p>
                                {recapElement !== null ? recapElement.title : '?'}
                            </p>
                        </div>
                    );
                })}
            </div>
            <img
                src={PatternHorizontalHud}
                alt=""
                className={styles.patternBottom}
            />
        </div>
    );
};

export default HeaderTvRecap;
