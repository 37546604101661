import { Magic } from '@/assets/sounds/soundsEffect';
import Star from '@/features/reusablecomponents/stars/Star';
import styles from '@/features/reusablecomponents/stars/starField.module.scss';
import { useGameStore } from '@/store/gameStore';
import { playSound } from '@/services/global/globalHandleFunctions';
import { useEffect, useState } from 'react';

const MAX_PERCENTAGE = 100;
const MAX_STARS = 25;
const INTERVAL_TIME = 20;

const StarField: React.FC = () => {
    const [stars, setStars] = useState<JSX.Element[]>([]);
    const currentGameResult = useGameStore.use.gameResult();

    useEffect(() => {
        const interval = setInterval(() => {
            const style = {
                top: `${Math.random() * MAX_PERCENTAGE}%`,
                left: `${Math.random() * MAX_PERCENTAGE}%`,
            };
            const newStar = <Star key={Math.random()} style={style} />;

            setStars((prevStars) => [...prevStars, newStar].slice(-MAX_STARS));
        }, INTERVAL_TIME);
        playSound(Magic);

        return () => clearInterval(interval);
    }, [currentGameResult]);

    return <div className={styles.starFieldContainer}>{stars}</div>;
};

export default StarField;
