import { type BoardShape } from '@/services/global/types';
// services
import dynamicFunctions from '@/services/device/dynamicFunctions';
import {
    handleGameStatusToString,
    handleSendGameStatus,
    handleUpdateDeviceActivatedStatus,
} from '@/services/global/globalHandleFunctions';
import {
    handleUpdateOverlayDescriptions,
    handleUpdateOverlayIndex,
} from '@/services/tv/tvHandleFunctions';

// datas
import i18n from '@/i18n';
import dataGlobal from '@/services/datas/global.json';
import {
    GAMESTATUS,
    type Behavior,
    type Emotion,
    type GameActionsKeys,
    type GameItem,
    type GameStatus,
    type GameStatusAction,
    type GameStatusActionWithoutGame5,
    type Item,
    type ItemsSelected,
    type Message,
} from '@/types/games/types';
import {
    type DeviceColorWithTvAndMediator,
    type OverlayDescription,
} from '@/types/global/types';
import type { ClientEvents } from '@/types/socket/types';
import { handleResetItemsFound } from '@/services/device/mediatorHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';

interface GoodCombination {
    emotionId?: number;
    symbolId?: number;
    weakEmotionId?: number;
    strongEmotionId?: number;
    familyId?: number;
    friendsId?: number;
    sportId?: number;
    workId?: number;
    behaviorId?: number;
    code?: string;
    idSituation?: number;
    idResponse?: number;
}

interface ValidateGameActionParams {
    emotionId?: number;
    symbolId?: number;
    currentBoard?: BoardShape;
    itemsSelected: ItemsSelected;
    gameStatus: GameStatusActionWithoutGame5;
}

export const handleTabletStatus = (
    deviceActivationStatus: Record<string, boolean>
): void => {
    Object.entries(deviceActivationStatus).forEach(([color, status]) => {
        handleUpdateDeviceActivatedStatus(
            color as DeviceColorWithTvAndMediator,
            status
        );
    });
};

/**
 *  config for tablet status on game status
 */
export const tabletStatusConfig = {
    [GAMESTATUS.WELCOME_SCREEN]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.INTRODUCTION]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME1ACTION1INTRODUCTION]: {
        blue: false,
        green: false,
        orange: true,
        red: false,
    },
    [GAMESTATUS.GAME1ACTION1]: {
        blue: false,
        green: true,
        orange: true,
        red: true,
    },
    [GAMESTATUS.GAME1ACTION2INTRODUCTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME1ACTION2]: {
        blue: true,
        green: false,
        orange: false,
        red: false,
    },

    [GAMESTATUS.GAME1ACTION2PREVENTION]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME1UNLOCK]: {
        blue: false,
        green: false,
        orange: false,
        red: true,
    },
    [GAMESTATUS.GAME2INTRODUCTION]: {
        green: true,
        orange: false,
        red: false,
        blue: false,
    },
    [GAMESTATUS.GAME2ACTION]: {
        green: true,
        orange: true,
        red: true,
        blue: true,
    },

    [GAMESTATUS.GAME2PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME2UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME3INTRODUCTION]: {
        green: false,
        orange: false,
        red: false,
        blue: true,
    },
    [GAMESTATUS.GAME3ACTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },

    [GAMESTATUS.GAME3PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME3UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME4INTRODUCTION]: {
        orange: true,
        blue: false,
        green: false,
        red: false,
    },
    [GAMESTATUS.GAME4ACTION]: {
        orange: true,
        blue: true,
        green: true,
        red: false,
    },

    [GAMESTATUS.GAME4PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME4UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME5INTRODUCTION]: {
        orange: false,
        blue: false,
        green: false,
        red: true,
    },
    [GAMESTATUS.GAME5ACTION]: {
        orange: true,
        blue: true,
        green: true,
        red: true,
    },

    [GAMESTATUS.GAME5PREVENTION]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.GAME5UNLOCK]: {
        green: false,
        orange: false,
        red: true,
        blue: false,
    },
    [GAMESTATUS.FINISHED]: {
        green: false,
        orange: false,
        red: false,
        blue: false,
    },
};

export const handleNextOrPreviousOverlayDescription = (
    action: 'next' | 'previous',
    gameStatus: GameStatus,
    overlayDescription: OverlayDescription[] | undefined,
    currentOverlayIndex: number
): void => {
    const nextGameStatus = getNextGameStatus(gameStatus);

    if (action === 'next') {
        const isLastOverlayMessage =
            overlayDescription &&
            currentOverlayIndex === overlayDescription.length - 1;

        if (isLastOverlayMessage) {
            handleGoToGameStatus(nextGameStatus as GameStatus);
        } else {
            handleUpdateOverlayIndex(currentOverlayIndex + 1);
        }
    } else if (action === 'previous') {
        const isFirstMessage = overlayDescription && currentOverlayIndex === 0;
        if (!isFirstMessage) handleUpdateOverlayIndex(currentOverlayIndex - 1);
    }
};

export const handleItemDetails = ({
    gameStatus,
    itemsSelected,
    type,
}: {
    gameStatus: GameStatus;
    itemsSelected: ItemsSelected | undefined;
    type: string;
}): {
    listItems: Emotion[] | Behavior[];
    itemIndex: number | string;
} => {
    const listItems: Emotion[] = i18n.t('emotions', {
        ns: 'global',
        returnObjects: true,
    }) as Emotion[];
    let itemIndex: number | string = -1;

    if (itemsSelected) {
        if (type === 'validation') {
            const idToMatch =
                gameStatus === GAMESTATUS.GAME4ACTION
                    ? itemsSelected?.behavior?.emotionId
                    : itemsSelected?.emotion?.id;
            itemIndex = listItems.findIndex((item) => item.id === idToMatch);
        } else if (type === 'found') {
            itemIndex =
                gameStatus === GAMESTATUS.GAME4ACTION ? 'behavior' : 'emotion';
        }
    }
    return {
        listItems,
        itemIndex,
    };
};

export const validateGameAction = ({
    itemsSelected,
    gameStatus,
    currentBoard,
}: ValidateGameActionParams): void => {
    let goodCombination: GoodCombination | boolean | undefined;
    const { listItems, itemIndex } = handleItemDetails({
        gameStatus,
        itemsSelected,
        type: 'validation',
    });
    if (gameStatus === GAMESTATUS.GAME1ACTION1) {
        goodCombination = (
            dataGlobal[gameStatus].goodCombinations as GoodCombination[]
        ).find(
            (combo) =>
                combo.emotionId === itemsSelected?.emotion?.id &&
                combo.symbolId === itemsSelected?.symbol?.id
        );
    } else if (gameStatus === GAMESTATUS.GAME1ACTION2) {
        goodCombination = (
            dataGlobal[gameStatus].goodCombinations as GoodCombination[]
        ).find(
            (combo) =>
                combo.weakEmotionId === itemsSelected?.weakEmotion?.id &&
                combo.emotionId === itemsSelected?.emotion?.id &&
                combo.strongEmotionId === itemsSelected?.strongEmotion?.id
        );
    } else if (gameStatus === GAMESTATUS.GAME2ACTION) {
        goodCombination = (
            dataGlobal[gameStatus].goodCombinations as GoodCombination[]
        ).find(
            (combo) =>
                combo.emotionId === itemsSelected?.emotion?.id &&
                combo.familyId === itemsSelected?.family?.id &&
                combo.friendsId === itemsSelected?.friends?.id &&
                combo.sportId === itemsSelected?.sport?.id &&
                combo.workId === itemsSelected?.work?.id
        );
    } else if (gameStatus === GAMESTATUS.GAME3ACTION) {
        const index = itemsSelected?.emotion?.id ? itemsSelected.emotion.id - 1 : 0;
        const goodBoardTetris =
            dataGlobal[gameStatus].goodCombinations[index].goodBoard;

        const foundGoodBoard = goodBoardTetris.find(
            (goodBoard) =>
                JSON.stringify(goodBoard) === JSON.stringify(currentBoard)
        );
        goodCombination = foundGoodBoard ? true : undefined;
    } else if (gameStatus === GAMESTATUS.GAME4ACTION) {
        goodCombination = dataGlobal[gameStatus].goodCombinations.find(
            (combo) =>
                combo.code === itemsSelected?.code &&
                combo.behaviorId === itemsSelected?.behavior?.id
        );
    }
    // magic number
    if (itemIndex !== -1) {
        const messageKey = goodCombination ? 'successMessage' : 'errorMessage';
        const item = listItems[itemIndex as number] as Emotion;
        const overlayDescriptions = item[messageKey][gameStatus as keyof Message];
        const indexSituation2 = 1;

        emitSocketEvent('send_update_validation', {
            result: goodCombination ? 'success' : 'error',
        });

        // Wait 1.5 second before update overlay to see the animation on the tv
        gameStatus === GAMESTATUS.GAME1ACTION1 ||
        gameStatus === GAMESTATUS.GAME1ACTION2 ||
        gameStatus === GAMESTATUS.GAME4ACTION
            ? setTimeout(() => {
                  handleUpdateOverlayDescriptions(
                      overlayDescriptions,
                      goodCombination ? 'success' : 'error'
                  );
              }, 1500)
            : handleUpdateOverlayDescriptions(
                  gameStatus === GAMESTATUS.GAME3ACTION
                      ? [overlayDescriptions[indexSituation2]]
                      : overlayDescriptions,
                  goodCombination ? 'success' : 'error'
              );
    }
};

export const validateGame5 = (
    itemsSelected: ItemsSelected,
    situationIndex: number,
    areAllPlayersAgree: boolean
): void => {
    let goodCombination = false;

    if (areAllPlayersAgree) {
        goodCombination =
            dataGlobal.GAME5ACTION.goodCombinations.find(
                (combo) => combo.idSituation === situationIndex
            )?.idResponse ===
            (itemsSelected?.red?.id &&
                itemsSelected?.green?.id &&
                itemsSelected?.blue?.id &&
                itemsSelected?.orange?.id);
    }

    emitSocketEvent('send_update_validation', {
        result: goodCombination ? 'success' : 'error',
    });

    setTimeout(() => {
        const overlayDescriptions = i18n.t(
            `GAME5ACTION.situations.${situationIndex - 1}.${
                goodCombination ? 'successMessage' : 'errorMessage'
            }`,
            {
                ns: 'global',
                returnObjects: true,
            }
        ) as OverlayDescription;

        if (situationIndex === dataGlobal.GAME5ACTION.situations.length) {
            handleGoToGameStatus(GAMESTATUS.GAME5PREVENTION);
        } else {
            emitSocketEvent('send_update_situation_game_5', {
                situationIndex,
            });
            if (areAllPlayersAgree) {
                handleUpdateOverlayDescriptions(
                    [overlayDescriptions],
                    goodCombination ? 'success' : 'error'
                );
            } else {
                handleUpdateOverlayDescriptions([overlayDescriptions], 'error');
            }
        }
    }, 1500);

    emitSocketEvent('send_reset_items_selected', {
        gameStatus: GAMESTATUS.GAME5ACTION,
    });
};

export const handleResetValidation = (gameStatus: GameStatus): void => {
    emitSocketEvent('send_reset_items_selected', {
        gameStatus,
    });
    handleSendDialValue('');
};

export const handleItemSelected = (
    item: GameItem,
    itemKey: GameActionsKeys,
    gameStatus: GameStatus
): void => {
    const completed = handleGameStatusToString(gameStatus);
    const nameFunction = `setItemsSelected${completed}`;
    const selectedFunction =
        dynamicFunctions[nameFunction as keyof typeof dynamicFunctions];

    if (typeof selectedFunction !== 'function') return;

    emitSocketEvent('send_update_item_selected', {
        item,
        itemKey,
        gameStatus: gameStatus as GameStatusAction,
    });

    const itemToUpdate = { item, itemKey };
    selectedFunction(itemToUpdate);
};

export const errorValidation = (gameStatus: GameStatus): void => {
    if (gameStatus === GAMESTATUS.GAME3ACTION) {
        handleTabletStatus({
            blue: false,
            green: false,
            orange: false,
            red: true,
        });
    } else {
        handleTabletStatus({
            blue: true,
            green: true,
            orange: true,
            red: true,
        });
    }
    handleUpdateOverlayDescriptions([], 'neutral');
    handleResetValidation(gameStatus);
};

export const successValidation = (
    gameStatus: GameStatus,
    itemsSelected: ItemsSelected,
    itemsFound: Item[],
    roomName: string
): void => {
    const { listItems, itemIndex } = handleItemDetails({
        gameStatus,
        itemsSelected,
        type: 'found',
    });
    const idFound =
        itemIndex === 'behavior'
            ? ('emotionId' as keyof Behavior)
            : ('id' as keyof Emotion);

    let itemFound: Emotion | Behavior | undefined;
    if (itemIndex === 'behavior' || itemIndex === 'emotion') {
        itemFound = listItems.find(
            (item: unknown) =>
                (item as Emotion | Behavior).id ===
                itemsSelected[itemIndex]?.[idFound as keyof typeof item]
        );
    }
    const responsesSocket = {
        roomName,
        itemFound,
    };

    const gameStatusString = handleGameStatusToString(gameStatus)?.toLowerCase();
    const titleSocket =
        `send_update_item_found_${gameStatusString}` as keyof ClientEvents;

    const itemsFoundFilledslots = itemsFound.filter((item) => item !== null).length;

    if (itemsFoundFilledslots === listItems.length - 1) {
        handleGoToGameStatus(getNextGameStatus(gameStatus));
        handleResetItemsFound(gameStatus);
    } else {
        handleGoToGameStatus(gameStatus);
        if (gameStatus === GAMESTATUS.GAME1ACTION1) {
            handleTabletStatus({
                blue: true,
                green: true,
                orange: true,
                red: true,
            });
        }
        emitSocketEvent(titleSocket, responsesSocket);
    }
    handleResetValidation(gameStatus);
};

// GAME 2
export const handleIsConfirmSelectionButton = (status: boolean): void => {
    emitSocketEvent('send_update_is_confirm_selection_button', { status });

    if (status) {
        handleTabletStatus({
            blue: false,
            green: true,
            orange: false,
            red: false,
        });
    } else
        handleTabletStatus({
            blue: true,
            green: true,
            orange: true,
            red: true,
        });
};

export const handleSendDialResetStatus = (dialResetStatus: boolean): void => {
    emitSocketEvent('send_reset_dial_status', { value: dialResetStatus });
};

export const handleSendDialValue = (dialValue: string): void => {
    emitSocketEvent('send_dial_value', {
        value: dialValue,
    });
};

export const validationListTetris = (
    gameStatus: GameStatus,
    itemsSelected?: ItemsSelected
): void => {
    const goodCombination = dataGlobal.GAME3ACTION.goodCombinations.find(
        (combo) =>
            combo.emotionId === itemsSelected?.emotion?.id &&
            combo.fanny === itemsSelected?.tetrisfanny &&
            combo.eric === itemsSelected?.tetriseric &&
            combo.paul === itemsSelected?.tetrispaul
    );

    const { listItems, itemIndex } = handleItemDetails({
        gameStatus,
        itemsSelected,
        type: 'validation',
    });

    const messageKey = goodCombination ? 'successMessage' : 'errorMessage';
    const item = listItems[itemIndex as number] as Emotion;
    const overlayDescriptions = item[messageKey][gameStatus as keyof Message];
    handleUpdateOverlayDescriptions(
        overlayDescriptions,
        goodCombination ? 'success' : 'error'
    );
};

export const handleSendCarouselValues = (
    key: 'rightIndex' | 'centerIndex' | 'leftIndex' | 'flowDirection',
    value: number
): void => {
    emitSocketEvent('send_carousel_values', { value, key });
};

export const getNextGameStatus = (gameStatus: GameStatus): GameStatus => {
    const indexCurrentGameStatus = Object.keys(GAMESTATUS).findIndex(
        (status) => status === gameStatus
    );

    return Object.keys(GAMESTATUS)[indexCurrentGameStatus + 1] as GameStatus;
};

export const handleGoToUnlockCode = (
    roomName: string,
    gameStatus: GameStatus
): void => {
    handleUpdateOverlayDescriptions(
        i18n.t(`${gameStatus}.tv`, {
            ns: 'overlayContent',
            returnObjects: true,
        }),
        'neutral'
    );
    handleSendGameStatus(gameStatus);
    handleTabletStatus({
        blue: false,
        green: false,
        orange: false,
        red: true,
    });
};

export const handleMouse = (typeMouse: string, actionType: string): void => {
    emitSocketEvent('send_click_type_tetris', {
        typeMouse,
        action: actionType,
    });
};

export const handleCarouselClick = (
    direction: string,
    itemsSelected: ItemsSelected,
    currentGameStatus: GameStatus,
    listBehaviors: Behavior[],
    leftIndex: number,
    centerIndex: number,
    rightIndex: number
): void => {
    const flowDirection = direction === 'next' ? 0 : 1;
    const indexChange = direction === 'next' ? 'decrement' : 'increment';

    handleNextItemSelected(
        direction,
        itemsSelected,
        listBehaviors,
        currentGameStatus as GameStatusAction
    );
    handleSendCarouselValues('flowDirection', flowDirection);
    handleIndexChange('leftIndex', indexChange, leftIndex, listBehaviors);
    handleIndexChange('centerIndex', indexChange, centerIndex, listBehaviors);
    handleIndexChange('rightIndex', indexChange, rightIndex, listBehaviors);
};

const handleIndexChange = (
    indexName: 'leftIndex' | 'rightIndex' | 'centerIndex',
    mode: 'increment' | 'decrement',
    currentIndex: number,
    listBehaviors: Behavior[]
) => {
    const behaviorsCount = listBehaviors.length;

    let newIndex = 0;
    if (mode === 'increment') {
        newIndex = (currentIndex + 1) % behaviorsCount;
    } else if (mode === 'decrement') {
        newIndex = (currentIndex - 1 + behaviorsCount) % behaviorsCount;
    }

    handleSendCarouselValues(indexName, newIndex);
};

const handleNextItemSelected = (
    type: string,
    itemsSelected: ItemsSelected,
    listBehaviors: Behavior[],
    currentGameStatus: GameStatusAction
) => {
    if (!itemsSelected.behavior) return;

    const item = getBehavior(itemsSelected.behavior.id, type, listBehaviors);
    if (item) {
        handleItemSelected(item, 'behavior', currentGameStatus);
    }
};

const getBehavior = (
    currentId: number,
    direction: string,
    listBehaviors: Behavior[]
) => {
    const behaviorsCount = listBehaviors.length;
    let newId = direction === 'next' ? currentId - 1 : currentId + 1;
    newId = ((newId + behaviorsCount - 1) % behaviorsCount) + 1;

    return listBehaviors.find((behavior) => behavior.id === newId);
};

/**
 *  handleGotoGameStatus init OverlayDescriptions with corresponding gameStatus datas and tablet configuration
 */
export const handleGoToGameStatus = (gameStatus: GameStatus): void => {
    handleUpdateOverlayIndex(0);
    handleSendGameStatus(gameStatus);
    handleTabletStatus(
        tabletStatusConfig[gameStatus as keyof typeof tabletStatusConfig]
    );

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION1 ||
        gameStatus === GAMESTATUS.GAME1ACTION2 ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION ||
        gameStatus === GAMESTATUS.GAME5ACTION;

    if (isGameAction) {
        handleUpdateOverlayDescriptions([], 'neutral');
    } else {
        handleUpdateOverlayDescriptions(
            i18n.t(`${gameStatus}.tv`, {
                ns: 'overlayContent',
                returnObjects: true,
            }),
            'neutral'
        );
    }
};
