import { motion } from 'framer-motion';

// scss
import globalStyle from '@/assets/styles/app.module.scss';
import styles from '/src/features/devices/tablets/orange/games/game4/chooseBehavior.module.scss';

// components
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

// utils
import {
    handleItemSelected,
    handleSendCarouselValues,
    handleTabletStatus,
} from '@/services/device/deviceHandleFunctions';
import { Device } from '@/services/global/types';

// services
import { RightArrow } from '@/assets/images/tablets';
import { Swipe } from '@/assets/sounds/soundsEffect';
import { useGameStore } from '@/store/gameStore';
import { useEffect } from 'react';

import { useGame4Store } from '@/store/games/game4Store';
import { useRoomStore } from '@/store/roomStore';
import { playSound } from '@/services/global/globalHandleFunctions';
import type { Behavior } from '@/types/games/types';

const ChooseBehavior: React.FC = () => {
    const roomName = useRoomStore.use.roomName();
    const currentGameStatus = useGameStore.use.gameStatus();
    const deviceColor = Device.orange;

    const itemsSelected = useGame4Store.use.itemsSelected();
    const { rightIndex, centerIndex, leftIndex } =
        useGame4Store.use.carouselValues();
    const listBehaviors = useGame4Store.use.listBehaviors();

    const itemsFound = useGame4Store.use.itemsFound();

    const getBehavior = (
        currentId: number,
        direction: 'next' | 'previous'
    ): Behavior | undefined => {
        const behaviorsCount = listBehaviors.length;
        let newId = direction === 'next' ? currentId - 1 : currentId + 1;
        newId = ((newId + behaviorsCount - 1) % behaviorsCount) + 1;

        return listBehaviors.find((behavior) => behavior.id === newId);
    };

    const handleNextItemSelected = (type: 'next' | 'previous') => {
        if (!itemsSelected.behavior) return;

        const item = getBehavior(itemsSelected.behavior.id, type);
        if (item) {
            handleItemSelected(item, 'behavior', currentGameStatus);
        }
    };

    const handleIndexChange = (
        indexName: 'leftIndex' | 'rightIndex' | 'centerIndex',
        mode: 'increment' | 'decrement',
        currentIndex: number
    ) => {
        const behaviorsCount = listBehaviors.length;

        let newIndex = 0;
        if (mode === 'increment') {
            newIndex = (currentIndex + 1) % behaviorsCount;
        } else if (mode === 'decrement') {
            newIndex = (currentIndex - 1 + behaviorsCount) % behaviorsCount;
        }

        handleSendCarouselValues(indexName, newIndex);
    };

    const handleButtonClick = (direction: 'next' | 'previous') => {
        const flowDirection = direction === 'next' ? 0 : 1;
        const indexChange = direction === 'next' ? 'decrement' : 'increment';

        handleNextItemSelected(direction);
        handleSendCarouselValues('flowDirection', flowDirection);
        handleIndexChange('leftIndex', indexChange, leftIndex);
        handleIndexChange('centerIndex', indexChange, centerIndex);
        handleIndexChange('rightIndex', indexChange, rightIndex);
    };

    useEffect(() => {
        if (itemsSelected.behavior?.behaviorFound) {
            handleTabletStatus({
                blue: false,
                green: false,
                orange: true,
                red: false,
            });
        } else {
            handleTabletStatus({
                blue: true,
                green: true,
                orange: true,
                red: false,
            });
        }
    }, [itemsSelected, roomName, itemsFound]);

    return (
        <div className={globalStyle.itemBoxContainer}>
            <TabletScreen
                step="action"
                deviceColor={deviceColor}
                typeBackground="banner"
            >
                <div className={styles.carouselBtns}>
                    <motion.button
                        whileTap={{ scale: 0.8 }}
                        onClick={() => {
                            handleButtonClick('previous');
                            playSound(Swipe);
                        }}
                        style={{
                            scaleX: -1,
                        }}
                    >
                        <img src={RightArrow} alt="flèche" />
                    </motion.button>
                    <motion.button
                        whileTap={{ scale: 0.8 }}
                        onClick={() => {
                            handleButtonClick('next');
                            playSound(Swipe);
                        }}
                    >
                        <img src={RightArrow} alt="flèche" />
                    </motion.button>
                </div>
            </TabletScreen>
        </div>
    );
};

export default ChooseBehavior;
