import { DiamondShapeMini } from '@/assets/images/tablets';
import styles from '@/features/reusablecomponents/global/inputUnlockCode/inputUnlockCode.module.scss';

import { useDialStore } from '@/store/dialStore';
import { useGame4Store } from '@/store/games/game4Store';

const InputUnlockCode: React.FC = () => {
    const maxCodeLength = useDialStore.use.codeInputMaxLength();

    const itemsSelected = useGame4Store.use.itemsSelected();
    const code = itemsSelected?.code?.split('') || [];

    return (
        <div className={styles.container}>
            {Array.from(Array(maxCodeLength).keys()).map((number, index) => {
                return (
                    <div key={number}>
                        {code && code[index] ? (
                            <p>{code[index]}</p>
                        ) : (
                            <img src={DiamondShapeMini} alt="icône losange" />
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default InputUnlockCode;
