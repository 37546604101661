import { motion } from 'framer-motion';

// utils
import { playSound } from '@/services/global/globalHandleFunctions';
import { isDevelopperMode } from '@/services/global/types';

// assets & styles
import styles from '@/features/reusablecomponents/buttons/button.module.scss';
import { useGameStore } from '@/store/gameStore';

interface Button {
    text: string;
    backgroundColor?: string;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    icon?: string;
    additionalStyle?: React.CSSProperties;
    disabled?: boolean;
    soundPath?: string;
}

const Button: React.FC<Button> = ({
    text,
    backgroundColor,
    onClick,
    icon,
    additionalStyle,
    disabled,
    soundPath,
}) => {
    const isAudioPlaying = useGameStore.use.isAudioPlaying();

    return (
        <motion.button
            className={`${styles.btn} ${styles[`${backgroundColor}-btn`]}`}
            style={{ ...additionalStyle }}
            type="button"
            onClick={onClick}
            disabled={isDevelopperMode ? false : isAudioPlaying || disabled}
            whileTap={{
                scale: 0.9,
            }}
            onMouseDown={() => soundPath && playSound(soundPath)}
        >
            {icon && <img src={icon} alt={icon} />} {text}
        </motion.button>
    );
};

export default Button;
