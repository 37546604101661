import {
    type Game1Store,
    type GameOneAction1,
    type GameOneAction2,
} from '@/types/games/game1/types';
import { type Game2Store, type Game2Action } from '@/types/games/game2/types';
import { type Game3Store, type Game3Action } from '@/types/games/game3/types';
import { type Game4Store, type Game4Action } from '@/types/games/game4/types';
import {
    type Game5Store,
    type ResponseSituation,
    type Game5Action,
} from '@/types/games/game5/types';
import type { OverlayDescription, Situation } from '@/types/global/types';

export interface GameStore {
    gameStatus: GameStatus;
    isAudioPlaying: boolean;
    gameResult: GameResult;
}

export type GamesStore =
    | Game1Store
    | Game2Store
    | Game3Store
    | Game4Store
    | Game5Store;

export type GameResult = 'success' | 'error' | '';

export type GameStatus = keyof typeof GAMESTATUS;

export type GameStatusAction = Extract<
    GameStatus,
    `GAME${number}ACTION${number | ''}`
>;

export type GameStatusActionWithoutGame5 = Exclude<GameStatusAction, 'GAME5ACTION'>;

export type Message = {
    [key in GameStatus]: OverlayDescription[];
};

export type HeaderContent = {
    title: string;
    text: string;
};

export type EmotionSecondary = Omit<Emotion, 'successMessage' | 'errorMessage'>;

export interface Item {
    id: number;
    slug: string;
    image?: string;
    title?: string;
}

export type _Symbol = Item;

export interface Emotion extends Item {
    description: string;
    successMessage: Message;
    errorMessage: Message;
}

export interface Behavior extends Item {
    name: string;
    behavior: string;
    emotionId: number;
    behaviorFound: boolean;
    audio: string;
}

export interface Stimulus extends Item {
    name: string;
    text: string;
    picto: string;
    sound: string;
}

export interface ItemFound extends Item {
    description: string;
    successMessage: Message;
    errorMessage: Message;
    isSelected: boolean;
}

export interface Character extends Item {
    name: string;
}

export interface ItemsSelected {
    emotion?: Emotion;
    strongEmotion?: EmotionSecondary;
    weakEmotion?: EmotionSecondary;
    symbol?: _Symbol;
    work?: Stimulus;
    family?: Stimulus;
    sport?: Stimulus;
    friends?: Stimulus;
    character?: Character;
    tetrispaul?: string;
    tetrisfanny?: string;
    tetriseric?: string;
    behavior?: Behavior;
    code?: string;
    red?: ResponseSituation;
    green?: ResponseSituation;
    blue?: ResponseSituation;
    orange?: ResponseSituation;
}

/**
 * type string is needed here for the Game4 since the Game store can accept a Behavior or a string as a type for the item property
 * it surely needs a refactor but it will impact a lot of code if we want to keep it generic
 */
export type GameItem =
    | Emotion
    | _Symbol
    | Stimulus
    | Behavior
    | ResponseSituation
    | Situation
    | string;

export type GameActionsKeys =
    | keyof GameOneAction1
    | keyof GameOneAction2
    | keyof Game2Action
    | keyof Game3Action
    | keyof Game4Action
    | keyof Game5Action;

export const GAMESTATUS = {
    WELCOME_SCREEN: 'WELCOME_SCREEN',
    INTRODUCTION: 'INTRODUCTION',

    GAME1ACTION1INTRODUCTION: 'GAME1ACTION1INTRODUCTION',
    GAME1ACTION1: 'GAME1ACTION1',

    GAME1ACTION2INTRODUCTION: 'GAME1ACTION2INTRODUCTION',
    GAME1ACTION2: 'GAME1ACTION2',
    GAME1ACTION2PREVENTION: 'GAME1ACTION2PREVENTION',
    GAME1UNLOCK: 'GAME1UNLOCK',

    GAME2INTRODUCTION: 'GAME2INTRODUCTION',
    GAME2ACTION: 'GAME2ACTION',
    GAME2PREVENTION: 'GAME2PREVENTION',
    GAME2UNLOCK: 'GAME2UNLOCK',

    GAME3INTRODUCTION: 'GAME3INTRODUCTION',
    GAME3ACTION: 'GAME3ACTION',
    GAME3PREVENTION: 'GAME3PREVENTION',
    GAME3UNLOCK: 'GAME3UNLOCK',

    GAME4INTRODUCTION: 'GAME4INTRODUCTION',
    GAME4ACTION: 'GAME4ACTION',
    GAME4PREVENTION: 'GAME4PREVENTION',
    GAME4UNLOCK: 'GAME4UNLOCK',

    GAME5INTRODUCTION: 'GAME5INTRODUCTION',
    GAME5ACTION: 'GAME5ACTION',
    GAME5PREVENTION: 'GAME5PREVENTION',
    GAME5UNLOCK: 'GAME5UNLOCK',

    FINISHED: 'FINISHED',
} as const;

export const INITIAL_GAME_STORE: GameStore = {
    gameStatus: GAMESTATUS.WELCOME_SCREEN,
    isAudioPlaying: false,
    gameResult: '',
};
