import createSelectors from '@/store/selectors';
import { deepCopy } from '@/services/global/globalUtils';
import {
    GAMESTATUS,
    type GameResult,
    type GameStatus,
    type GameStore,
} from '@/types/games/types';
import { create } from 'zustand';

const INITIAL_GAME_STORE: GameStore = {
    gameStatus: GAMESTATUS.WELCOME_SCREEN,
    isAudioPlaying: false,
    gameResult: '',
};

const useGameStoreBase = create<GameStore>(() => deepCopy(INITIAL_GAME_STORE));

export const useGameStore = createSelectors(useGameStoreBase);

export const setGameStatus = (gameStatus: GameStatus): void => {
    useGameStoreBase.setState({
        gameStatus,
    });
};

export const setIsAudioPlaying = (isAudioPlaying: boolean): void => {
    useGameStoreBase.setState({
        isAudioPlaying,
    });
};

export const setGameResult = (result: GameResult): void => {
    useGameStore.setState({ gameResult: result });
};

export const resetGame = (): void => {
    useGameStoreBase.setState(deepCopy(INITIAL_GAME_STORE));
};
