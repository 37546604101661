import { create } from 'zustand';
import { deepCopy } from '@/services/global/globalUtils';
import type { DialStore } from '@/types/global/types';
import createSelectors from '@/store/selectors';

const INITIAL_DIAL_STORE: DialStore = {
    value: '',
    reset: false,
    codeInputMaxLength: 2,
};

const useDialStoreBase = create(() => deepCopy(INITIAL_DIAL_STORE));

export const useDialStore = createSelectors(useDialStoreBase);
