import { useTranslation } from 'react-i18next';

// assets & styles
import { Scientist } from '@/assets/images/characters';
import styles from '@/features/devices/tv/components/welcome/welcomeScreen.module.scss';
import { useRoomStore } from '@/store/roomStore';
import SocketService from '@/services/SocketService';

interface WelcomeScreenProps {
    isWelcomeScreen: boolean;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ isWelcomeScreen }) => {
    const { t } = useTranslation('global');
    const roomName = useRoomStore.use.roomName();

    const changeLanguage = (language: string) => {
        const responseSocket = {
            language,
            roomName,
        };
        const socket = SocketService.getInstance().getSocket();
        socket?.emit('send_update_language', responseSocket);
    };

    return (
        <div className={styles.welcomeScreenContainer}>
            <p>
                {isWelcomeScreen
                    ? t('gameContent.title')
                    : t('gameContent.finishText')}
            </p>
            <img src={Scientist} alt={t('gameContent.avatar')} />
            <div className={styles.languages}>
                <button onClick={() => changeLanguage('fr-FR')}>🇫🇷</button>
                <button onClick={() => changeLanguage('ko-KO')}>🇰🇷</button>
                <button onClick={() => changeLanguage('ja-JA')}>🇯🇵</button>
            </div>
        </div>
    );
};

export default WelcomeScreen;
