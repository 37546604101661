// components
import ErrorAndSuccessBackground from '@/features/reusablecomponents/global/errorAndSuccessBackground/ErrorAndSuccessBackground';

// scss
import styles from '@/features/devices/tv/components/overlay/overlay.module.scss';

// assets
import {
    Scientist,
    ScientistError,
    ScientistSuccess,
} from '@/assets/images/characters';

import {
    Eye,
    PatternBackground,
    PatternHorizontal,
    PatternLeft,
    PatternRight,
    PatternTriangle,
} from '@/assets/images/tv';
import { Lose, Win } from '@/assets/sounds/soundsEffect';
import AudioComponent from '@/features/reusablecomponents/global/AudioComponent';
import TextAnimation from '@/features/reusablecomponents/text/TextAnimation';
import { useOverlayStore } from '@/store/overlayStore';
import { playSound } from '@/services/global/globalHandleFunctions';
import type { OverlayType } from '@/types/global/types';

interface OverlayProps {
    type?: OverlayType;
}

const Overlay: React.FC<OverlayProps> = ({ type = 'neutral' }) => {
    const handleAssets = () => {
        switch (type) {
            case 'neutral':
                return Scientist;
            case 'success':
                playSound(Win);
                return ScientistSuccess;
            case 'error':
                playSound(Lose);
                return ScientistError;
            default:
                return Scientist;
        }
    };
    const currentOverlayDescriptions = useOverlayStore.use.descriptions();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();
    const currentTextAudio = currentOverlayDescriptions[currentOverlayIndex]?.audio;
    const currentTitle = currentOverlayDescriptions[currentOverlayIndex]?.title;
    const currentText = currentOverlayDescriptions[currentOverlayIndex]?.text;

    return (
        <div className={styles.overlayContainer}>
            {currentTextAudio && <AudioComponent src={currentTextAudio} />}
            {type !== 'neutral' && type !== 'unlock' && (
                <ErrorAndSuccessBackground type={type} device="tv" />
            )}
            <img src={PatternHorizontal} alt="" className={styles.patternTop} />
            <img
                src={PatternBackground}
                alt="Arrière plan"
                className={styles.patternBackground}
            />
            <div
                className={
                    type === 'error'
                        ? styles.containerOverlayTvError
                        : styles.containerOverlayTv
                }
            >
                <img src={PatternLeft} alt="" className={styles.patternLeft} />
                {type === 'unlock' ? (
                    <div className={styles.containerUnlockGame}>
                        <h1>{currentTitle}</h1>
                        <img src={PatternTriangle} alt="" />
                    </div>
                ) : (
                    <>
                        <img
                            src={handleAssets()}
                            alt="Scientifique"
                            className={styles.character}
                        />
                        <div className={styles.textContainer}>
                            <div>
                                <img src={Eye} alt="Icone" />
                                <h1>{currentTitle}</h1>
                            </div>
                            <TextAnimation text={currentText as string} />
                        </div>
                    </>
                )}
                <img src={PatternRight} alt="" className={styles.patternRight} />
            </div>
            <img src={PatternHorizontal} alt="" className={styles.patternBottom} />
        </div>
    );
};

export default Overlay;
