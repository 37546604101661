import { useRoomStore } from '@/store/roomStore';
import { handleSendIsAudioPlaying } from '@/services/global/globalHandleFunctions';
import { useEffect } from 'react';

interface AudioProps {
    src: string;
    trackSource?: string;
    onEndedCallback?: () => void;
}

const AudioComponent: React.FC<AudioProps> = ({ src, trackSource }) => {
    const roomName = useRoomStore.use.roomName();

    const onPlay = () => {
        handleSendIsAudioPlaying(true, roomName);
    };

    const onEnd = () => {
        handleSendIsAudioPlaying(false, roomName);
    };

    useEffect(() => {
        return () => {
            handleSendIsAudioPlaying(false, roomName);
        };
    });

    return (
        <audio
            autoPlay
            src={src}
            onEnded={() => onEnd()}
            onPlaying={() => onPlay()}
        >
            <track kind="captions" srcLang="fr" src={trackSource} />
        </audio>
    );
};

export default AudioComponent;
