import { RightArrow, Rotate } from '@/assets/images/global';

// services

import styles from '@/features/devices/tablets/components/game3/remoteTetris.module.scss';
import { handleMouse } from '@/services/device/deviceHandleFunctions';

interface RemoteTetrisProps {
    type: 'up' | 'right' | 'left' | 'down';
}
const RemoteTetris: React.FC<RemoteTetrisProps> = ({ type }) => {
    const handleImageSource = () => {
        switch (type) {
            case 'left':
            case 'right':
            case 'down':
                return RightArrow;
            case 'up':
                return Rotate;
        }
    };

    return (
        <button
            id={type}
            name={type}
            className={styles.remoteTetrisButton}
            onMouseDown={() => {
                handleMouse('down', type);
            }}
            onMouseUp={() => {
                handleMouse('up', type);
            }}
        >
            {/* up = rotation */}
            {/* right = droite */}
            {/* left = gauche */}
            {/* down = speed */}
            <img src={handleImageSource()} alt="flèche" className={styles[type]} />
        </button>
    );
};

export default RemoteTetris;
