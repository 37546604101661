import { DeleteDialButton } from '@/assets/images/tablets';
import { PadClick } from '@/assets/sounds/soundsEffect';
import styles from '@/features/reusablecomponents/global/dial/digitKeyboard.module.scss';
import { useGameStore } from '@/store/gameStore';
import { motion } from 'framer-motion';

import { handleItemSelected } from '@/services/device/deviceHandleFunctions';
import { playSound } from '@/services/global/globalHandleFunctions';
import { useDialStore } from '@/store/dialStore';
import { useGame4Store } from '@/store/games/game4Store';
import type { GameItem } from '@/types/games/types';

const KEYBOARD_SIZE = 10;

const DigitKeyboard: React.FC = () => {
    const gameStatus = useGameStore.use.gameStatus();
    const maxCodeLength = useDialStore.use.codeInputMaxLength();
    const itemsSelected = useGame4Store.use.itemsSelected();
    const itemsSelectedCode = itemsSelected.code || '';

    const handleStyleSelectedDial = (number: string) => {
        if (itemsSelectedCode?.includes(number)) {
            return styles.selected;
        }
    };
    const handleClick = (type: 'add' | 'remove', number?: string) => {
        let code;
        if (type === 'add' && number) {
            code = itemsSelectedCode?.concat(number);
        }
        if (type === 'remove') {
            code = itemsSelectedCode?.slice(0, -1);
        }
        handleItemSelected(code as GameItem, 'code', gameStatus);
    };

    return (
        <div className={styles.padContainer}>
            {Array.from(Array(KEYBOARD_SIZE).keys()).map((number) => {
                if (number === 0) {
                    return;
                }
                return (
                    <motion.button
                        disabled={itemsSelectedCode?.length === maxCodeLength}
                        className={`${styles.button}
                        ${handleStyleSelectedDial(number.toString())}

                        `}
                        key={number}
                        whileTap={{
                            scale: 0.8,
                        }}
                        onClick={() => {
                            handleClick('add', number.toString());
                            playSound(PadClick);
                        }}
                    >
                        <span>{number}</span>
                    </motion.button>
                );
            })}
            <motion.button
                disabled={itemsSelectedCode?.length === maxCodeLength}
                className={`${styles.button} ${handleStyleSelectedDial('0')}`}
                whileTap={{
                    scale: 0.8,
                }}
                onClick={() => {
                    handleClick('add', '0');
                }}
            >
                <span>0</span>
            </motion.button>
            <motion.button
                className={`${styles.button}`}
                whileTap={{
                    scale: 0.8,
                    background:
                        'radial-gradient(109698.01% 100% at 50.01% 0%,#86ede7 0%,#3aa8a1 100%)',
                }}
                onClick={() => {
                    handleClick('remove');
                }}
            >
                <img src={DeleteDialButton} alt="supprimer entrée" />
            </motion.button>
        </div>
    );
};

export default DigitKeyboard;
