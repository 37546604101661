import { useEffect } from 'react';

import styles from '@/features/devices/tv/components/games/game3/Game3Action.module.scss';
import Board from '@/features/devices/tv/components/games/game3/board/Board';

import { useTetris } from '@/features/devices/tv/components/games/game3/hooks/useTetris';
import UpcomingBlocks from '@/features/devices/tv/components/games/game3/upcoming/UpcomingBlocks';
import { useGameStore } from '@/store/gameStore';

import { useGame3Store } from '@/store/games/game3Store';
import { useRoomStore } from '@/store/roomStore';
import {
    handleTabletStatus,
    validateGameAction,
} from '@/services/device/deviceHandleFunctions';
import { type Block } from '@/services/global/types';
import type { GameStatusActionWithoutGame5 } from '@/types/games/types';
import { emitSocketEvent } from '@/services/global/globalUtils';

const Game3: React.FC = () => {
    const {
        board,
        startTetrisGame,
        upcomingBlocks,
        handleClick,
        updateUpcomingBlocks,
        tetrisFinish,
        tetrisAutoCompletion,
    } = useTetris();

    const roomName = useRoomStore.use.roomName();

    const statusTetris = useGame3Store.use.statusTetris();
    const buttonTetris = useGame3Store.use.buttonTetris();
    const itemsSelected = useGame3Store.use.itemsSelected();
    const itemsFound = useGame3Store.use.itemsFound();

    const currentGameStatus = useGameStore.use.gameStatus();

    useEffect(() => {
        const selectedBlocks = [];
        switch (statusTetris) {
            case 'start':
                startTetrisGame(itemsSelected);
                break;
            case 'not-ready':
                if (itemsSelected?.tetrisfanny) {
                    selectedBlocks.push({
                        tetris: itemsSelected.tetrisfanny,
                        character: 'fanny',
                    } as Block);
                }
                if (itemsSelected?.tetrispaul) {
                    selectedBlocks.push({
                        tetris: itemsSelected.tetrispaul,
                        character: 'paul',
                    } as Block);
                }
                if (itemsSelected?.tetriseric) {
                    selectedBlocks.push({
                        tetris: itemsSelected.tetriseric,
                        character: 'eric',
                    } as Block);
                }
                updateUpcomingBlocks(selectedBlocks);
                break;
            case 'tetris-auto-completed':
                tetrisAutoCompletion(itemsSelected?.emotion?.id);
                break;
            default:
                break;
        }
    }, [
        itemsSelected,
        startTetrisGame,
        statusTetris,
        updateUpcomingBlocks,
        tetrisAutoCompletion,
        roomName,
    ]);

    useEffect(() => {
        if (buttonTetris.typeMouse && buttonTetris.actionMouse)
            handleClick(buttonTetris.typeMouse, buttonTetris.actionMouse);
    }, [buttonTetris.actionMouse, buttonTetris.typeMouse, handleClick]);
    useEffect(() => {
        if (
            itemsSelected.emotion &&
            itemsSelected.tetriseric &&
            itemsSelected.tetrisfanny &&
            itemsSelected.tetrispaul &&
            tetrisFinish
        ) {
            handleTabletStatus({
                blue: false,
                green: false,
                orange: false,
                red: true,
            });
            validateGameAction({
                itemsSelected,
                currentBoard: board,
                gameStatus: currentGameStatus as GameStatusActionWithoutGame5,
            });

            emitSocketEvent('send_update_status_tetris', {
                statusTetris: 'waiting-validation',
            });
        }
    }, [
        board,
        currentGameStatus,
        itemsFound,
        itemsSelected,
        roomName,
        tetrisFinish,
    ]);

    return (
        <div className={styles.boardTetris}>
            <Board currentBoard={board} />
            <UpcomingBlocks upcomingBlocks={upcomingBlocks} />
        </div>
    );
};

export default Game3;
