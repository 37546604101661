// scss && assets
import { PatternHorizontalCard } from '@/assets/images/tablets/index';
import styles from '@/features/devices/tablets/components/game2Action/chooseStimuli.module.scss';
import globalStyle from '@/assets/styles/app.module.scss';
import { motion } from 'framer-motion';

// components
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import { handleItemSelected } from '@/services/device/deviceHandleFunctions';
import { isObjectEmpty } from '@/services/device/deviceUtils';
import { playSound } from '@/services/global/globalHandleFunctions';
import { type Device } from '@/services/global/types';
import { useGameStore } from '@/store/gameStore';
import { useGame2Store } from '@/store/games/game2Store';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { EnvironmentsKey } from '@/types/games/game2/types';
import type { GameActionsKeys, Stimulus } from '@/types/games/types';

interface ChooseStimuliProps {
    type: keyof EnvironmentsKey;
}

const ChooseStimuli: React.FC<ChooseStimuliProps> = ({ type }) => {
    const itemsSelected = useGame2Store.use.itemsSelected();
    const { t } = useTranslation(['global', 'actionContent']);

    const [headerContent, setHeaderContent] = useState({
        title: '',
        text: '',
    });
    const gameStatus = useGameStore.use.gameStatus();
    const handleSelectStimulus = (item: Stimulus, type: GameActionsKeys) => {
        handleItemSelected(item, type, gameStatus);
        playSound(item.sound);
    };

    const handleTextHeader = useCallback(
        (type: string) => {
            const colors = {
                friends: 'orange' as Device,
                work: 'blue' as Device,
                family: 'red' as Device,
                sport: 'green' as Device,
            };

            const color = colors[type as keyof typeof colors];

            setHeaderContent({
                title: t(`${gameStatus}.${color}.headerContent.title`, {
                    ns: 'actionContent',
                }),
                text: t(`${gameStatus}.${color}.headerContent.text`, {
                    ns: 'actionContent',
                }),
            });
        },
        [t, gameStatus]
    );
    useEffect(() => {
        handleTextHeader(type);
    }, [type, itemsSelected, handleTextHeader]);

    const handleSelectedStimuli = (id: number) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { emotion, ...selectedItems } = itemsSelected;
        return Object.values(selectedItems).some((item) => item && item.id === id);
    };
    const stimuli = t(`GAME2ACTION.environments.${type}.stimuli`, {
        returnObjects: true,
    }) as Stimulus[];

    return (
        <TabletScreen
            header={{
                title: headerContent.title,
                text: headerContent.text,
            }}
            typeBackground="empty"
        >
            <div className={globalStyle.itemBoxContainer}>
                <div className={styles.stimuliContainer}>
                    {stimuli.map((stimulus) => (
                        <div className={styles.cardContainer} key={stimulus.id}>
                            <motion.button
                                whileTap={{ scale: 0.9 }}
                                onClick={() => {
                                    handleSelectStimulus(stimulus, type);
                                }}
                                className={
                                    handleSelectedStimuli(
                                        stimulus.id as Stimulus['id']
                                    )
                                        ? styles.selected
                                        : ''
                                }
                            >
                                <img src={stimulus.image} alt="carte stimulus" />
                            </motion.button>
                            <img
                                src={PatternHorizontalCard}
                                alt=""
                                className={styles.patternHorizontalCard}
                            />
                            <p>{stimulus.name}</p>
                        </div>
                    ))}
                </div>
            </div>
            {!isObjectEmpty(itemsSelected.work) &&
                !isObjectEmpty(itemsSelected.family) &&
                !isObjectEmpty(itemsSelected.friends) &&
                !isObjectEmpty(itemsSelected.sport) && (
                    <div className={styles.btnBottom}>
                        <ActionButton
                            title={t('buttons.validate')}
                            action="handleIsConfirmSelectionButton"
                            type="neutral"
                        />
                    </div>
                )}
        </TabletScreen>
    );
};

export default ChooseStimuli;
