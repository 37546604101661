import Button from '@/features/reusablecomponents/buttons/Button';
import styles from '@/features/reusablecomponents/global/waitingPlayers/createRoom.module.scss';
import { handleStartGame } from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

interface CreateRoomProps {
    onRoomIdChosen: (roomName: string) => void;
    type: string;
}

const CreateRoom: React.FC<CreateRoomProps> = ({ onRoomIdChosen, type }) => {
    const [text, setText] = useState('');
    const [isRoomValidate, setIsRoomValidate] = useState(false);
    const { t } = useTranslation('global');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const handleConnectToSocketRoom = () => {
        emitSocketEvent('send_join_room', {
            device: type as DeviceColorWithTvAndMediator,
        });
        setIsRoomValidate(true);
        if (type === 'mediator') onRoomIdChosen(text);
    };

    const handleClick = () => {
        handleStartGame(type as DeviceColorWithTvAndMediator);
        if (type !== 'mediator') onRoomIdChosen(text);
    };

    return (
        <div className={styles.container}>
            <div>
                {!isRoomValidate ? (
                    <>
                        <input
                            type="text"
                            value={text}
                            placeholder={t('buttons.pin')}
                            onChange={handleInputChange}
                        />
                        <button onClick={handleConnectToSocketRoom}>
                            {t('buttons.validate')}
                        </button>
                    </>
                ) : (
                    <Button
                        text={t('buttons.validate')}
                        backgroundColor="green"
                        onClick={handleClick}
                    />
                )}
            </div>
        </div>
    );
};

export default CreateRoom;
