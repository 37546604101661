import { useGame1Store } from '@/store/games/game1Store';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGame4Store } from '@/store/games/game4Store';
import { useMemo } from 'react';
import {
    GAMESTATUS,
    type GameStatusActionWithoutGame5,
    type Item,
} from '@/types/games/types';

export const useItemsFound = (gameStatus: GameStatusActionWithoutGame5): Item[] => {
    const itemsFoundGame1Action1 = useGame1Store.use.itemsFound();
    const itemsFoundGame1Action2 = useGame1Store.use.itemsFound();
    const itemsFoundGame2 = useGame2Store.use.itemsFound();
    const itemsFoundGame3 = useGame3Store.use.itemsFound();
    const itemsFoundGame4 = useGame4Store.use.itemsFound();

    const itemsFound = useMemo(
        () => ({
            [GAMESTATUS.GAME1ACTION1]: itemsFoundGame1Action1,
            [GAMESTATUS.GAME1ACTION2]: itemsFoundGame1Action2,
            [GAMESTATUS.GAME2ACTION]: itemsFoundGame2,
            [GAMESTATUS.GAME3ACTION]: itemsFoundGame3,
            [GAMESTATUS.GAME4ACTION]: itemsFoundGame4,
        }),
        [
            itemsFoundGame1Action1,
            itemsFoundGame1Action2,
            itemsFoundGame2,
            itemsFoundGame3,
            itemsFoundGame4,
        ]
    );

    return itemsFound[gameStatus];
};
