import type { Character, Emotion, HeaderContent } from '../types';

export interface Game3Store {
    itemsSelected: Game3Action;
    itemsFound: Emotion[];
    headerContent: HeaderContent;
    statusTetris: string;
    buttonTetris: ButtonTetris;
}

export interface Game3Action {
    character: Character;
    emotion: Emotion;
    tetriseric: string;
    tetrispaul: string;
    tetrisfanny: string;
}

export interface ButtonTetris {
    typeMouse: string | undefined;
    actionMouse: string | undefined;
}

export type TetrisStatus =
    | 'start'
    | 'tetris-auto-completed'
    | 'not-ready'
    | 'waiting-validation';

export const INITIAL_GAME3_STORE: Game3Store = {
    itemsSelected: {} as Game3Action,
    itemsFound: Array(4).fill(null),
    headerContent: {} as HeaderContent,
    statusTetris: 'not-ready',
    buttonTetris: {
        typeMouse: undefined,
        actionMouse: undefined,
    },
};
