// scss
import styles from '@/features/devices/tablets/components/game5/vote.module.scss';

import { motion } from 'framer-motion';

// utils
import { handleItemSelected } from '@/services/device/deviceHandleFunctions';
import { type DeviceColor } from '@/services/global/types';

// services
import { useGameStore } from '@/store/gameStore';

// datas
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import ChooseAnswer from '@/features/devices/tablets/components/chooseAnswer/ChooseAnswer';
import StarField from '@/features/reusablecomponents/stars/StarField';
import { useGame5Store } from '@/store/games/game5Store';
import { useOverlayStore } from '@/store/overlayStore';
import { useTranslation } from 'react-i18next';
import type { Situation } from '@/types/global/types';

interface VoteProps {
    deviceColor: DeviceColor;
}

const Vote: React.FC<VoteProps> = ({ deviceColor }) => {
    const gameStatus = useGameStore.use.gameStatus();
    const currentIndex = useGame5Store.use.currentIndex();
    const itemsSelected = useGame5Store.use.itemsSelected();
    const overlay = useOverlayStore.use.overlayType();
    const currentGameResult = useGameStore.use.gameResult();

    const { t } = useTranslation(['global', 'actionContent']);
    const currentSituation = t(`${gameStatus}.situations.${currentIndex}`, {
        returnObjects: true,
    }) as Situation;

    return (
        <div className={styles.voteContainer}>
            {overlay === 'neutral' ? (
                <>
                    <ChooseAnswer
                        responses={currentSituation?.responses}
                        deviceColor={deviceColor}
                        status={currentGameResult}
                    />
                    <div className={styles.buttonsContainer}>
                        {currentSituation?.responses?.map((response) => {
                            const isSelected =
                                itemsSelected[
                                    deviceColor as keyof typeof itemsSelected
                                ]?.id === response.id;
                            return (
                                <motion.button
                                    whileTap={{ scale: 0.9 }}
                                    key={response.id}
                                    onClick={() => {
                                        handleItemSelected(
                                            response,
                                            deviceColor,
                                            gameStatus
                                        );
                                    }}
                                    className={`${styles.button} ${
                                        isSelected && !currentGameResult
                                            ? styles.selected
                                            : undefined
                                    } ${
                                        isSelected && currentGameResult
                                            ? styles[currentGameResult]
                                            : undefined
                                    }`}
                                    disabled={currentGameResult !== ''}
                                >
                                    {t(
                                        `${gameStatus}.tv.bodyContent.responseTitle`,
                                        { ns: 'actionContent' }
                                    )}
                                    {response.id}
                                </motion.button>
                            );
                        })}
                        {currentGameResult === 'success' && <StarField />}
                    </div>
                </>
            ) : (
                <ActionButton
                    title={t(`buttons.continue`)}
                    type={overlay}
                    action="validateGame5"
                />
            )}
        </div>
    );
};

export default Vote;
