import styles from '@/features/devices/tablets/components/actionButton/actionButton.module.scss';

// components
import Button from '@/features/reusablecomponents/buttons/Button';
import ErrorAndSuccessBackground from '@/features/reusablecomponents/global/errorAndSuccessBackground/ErrorAndSuccessBackground';

// reducers
import { useRoomStore } from '@/store/roomStore';

// utils
import { Click } from '@/assets/sounds/soundsEffect';
import {
    errorValidation,
    getNextGameStatus,
    handleGoToGameStatus,
    handleIsConfirmSelectionButton,
    handleNextOrPreviousOverlayDescription,
    handleTabletStatus,
    successValidation,
    validateGameAction,
    validationListTetris,
} from '@/services/device/deviceHandleFunctions';

import { useGameStore } from '@/store/gameStore';
import { useGame2Store } from '@/store/games/game2Store';
import { useOverlayStore } from '@/store/overlayStore';
import { useItemsFound } from '@/services/hooks/useItemFounds';
import { useItemsSelected } from '@/services/hooks/useItemSelected';
import {
    handleStartTetris,
    handleUpdateOverlayDescriptions,
} from '@/services/tv/tvHandleFunctions';
import {
    GAMESTATUS,
    type GameStatus,
    type GameStatusActionWithoutGame5,
} from '@/types/games/types';
import { type OverlayType } from '@/types/global/types';

type ActionHandlers = {
    [K in ActionType]: () => void;
};

interface ActionButtonProps {
    title: string;
    action: ActionType;
    type: OverlayType;
}

export type ActionType =
    | 'goToNextIntroductionOverlay'
    | 'goToNextGameStatus'
    | 'handleValidationGame1Action1'
    | 'handleValidationGame1Action2'
    | 'handleIsConfirmSelectionButton'
    | 'handleValidationGame2Action'
    | 'errorValidation'
    | 'startTetris'
    | 'successValidation'
    | 'handleValidationGame4'
    | 'validationListTetris'
    | 'tryAgainShapeSelection'
    | 'validateGame5'
    | 'goToNextPreventOverlay';

const ActionButton: React.FC<ActionButtonProps> = ({ title, action, type }) => {
    const roomName = useRoomStore.use.roomName();

    const overlayInformations = useOverlayStore.use.descriptions();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();

    const currentGameStatus = useGameStore.use.gameStatus();

    const isConfirmSelectionButton = useGame2Store.use.isConfirmSelectionButton();

    const itemsSelected = useItemsSelected(
        currentGameStatus as GameStatusActionWithoutGame5
    );
    const itemsFound = useItemsFound(
        currentGameStatus as GameStatusActionWithoutGame5
    );

    const actionHandlers: ActionHandlers = {
        goToNextIntroductionOverlay: () => {
            handleNextOrPreviousOverlayDescription(
                'next',
                currentGameStatus,
                overlayInformations,
                currentOverlayIndex
            );
        },
        goToNextGameStatus: () => {
            handleGoToGameStatus(
                getNextGameStatus(currentGameStatus) as GameStatus
            );
        },
        handleValidationGame1Action1: () => {
            if (itemsSelected?.emotion && itemsSelected.symbol) {
                validateGameAction({
                    itemsSelected,
                    gameStatus: GAMESTATUS.GAME1ACTION1,
                });
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: true,
                    red: false,
                });
            }
        },
        handleValidationGame1Action2: () => {
            validateGameAction({
                itemsSelected,
                gameStatus: GAMESTATUS.GAME1ACTION2,
            });
            handleTabletStatus({
                blue: false,
                green: false,
                orange: false,
                red: true,
            });
        },
        handleIsConfirmSelectionButton: () =>
            handleIsConfirmSelectionButton(!isConfirmSelectionButton),
        handleValidationGame2Action: () => {
            validateGameAction({
                itemsSelected,
                gameStatus: GAMESTATUS.GAME2ACTION,
            });
            handleTabletStatus({
                blue: false,
                green: false,
                orange: false,
                red: true,
            });
        },
        errorValidation: () => errorValidation(currentGameStatus),
        startTetris: () => handleStartTetris(),
        successValidation: () =>
            itemsSelected &&
            successValidation(
                currentGameStatus,
                itemsSelected,
                itemsFound,
                roomName
            ),
        handleValidationGame4: () => {
            if (itemsSelected?.code && itemsSelected?.behavior) {
                validateGameAction({
                    itemsSelected,
                    gameStatus: currentGameStatus as GameStatusActionWithoutGame5,
                });
                handleTabletStatus({
                    blue: false,
                    green: true,
                    orange: false,
                    red: false,
                });
            }
        },
        validationListTetris: () => {
            if (itemsSelected?.emotion) {
                validationListTetris(currentGameStatus, itemsSelected);
                handleTabletStatus({
                    blue: false,
                    green: false,
                    orange: false,
                    red: true,
                });
            }
        },
        tryAgainShapeSelection: () => {
            handleUpdateOverlayDescriptions([], 'neutral');
            handleTabletStatus({
                blue: true,
                green: true,
                orange: true,
                red: true,
            });
        },
        validateGame5: () => handleUpdateOverlayDescriptions([], 'neutral'),
        goToNextPreventOverlay: () =>
            handleNextOrPreviousOverlayDescription(
                'next',
                currentGameStatus,
                overlayInformations,
                currentOverlayIndex
            ),
    };

    const handleClick = () => {
        const handler = actionHandlers[action];
        if (handler) {
            handler();
        }
    };

    return (
        <div className={styles.buttonContainer}>
            {type !== 'neutral' && type !== 'unlock' && (
                <ErrorAndSuccessBackground type={type} device="tablet" />
            )}
            <Button
                text={title}
                onClick={handleClick}
                soundPath={Click}
                backgroundColor={type}
            />
        </div>
    );
};

export default ActionButton;
