import styles from '@/features/devices/tv/components/games/game3/shape/shape.module.scss';
import { useGame3Store } from '@/store/games/game3Store';
import { type BlockShape } from '@/services/global/types';
import { motion } from 'framer-motion';

interface ShapeProps {
    shape: BlockShape;
    blockIndex: number;
    block: string;
    onClick?: () => void;
    character: 'eric' | 'fanny' | 'paul';
    isUpcoming: boolean;
}

const Shape: React.FC<ShapeProps> = ({
    shape,
    blockIndex,
    block,
    onClick,
    character,
    isUpcoming,
}) => {
    const itemsSelected = useGame3Store.use.itemsSelected();

    const isSelected = itemsSelected[`tetris${character}`] === block;

    const handleContainerStyle = () => {
        if (isUpcoming) return styles.shapeContainerUpcoming;
        if (isSelected) return styles[`${character}ShapeContainerSelected`];
        return styles.shapeContainer;
    };

    return (
        <motion.button
            className={handleContainerStyle()}
            onClick={onClick}
            whileTap={{
                scale: 0.9,
            }}
        >
            {shape.map((row, rowIndex) => {
                return (
                    <span key={rowIndex} className={styles.row}>
                        {row.map((isSet, cellIndex) => {
                            const cellClass = isSet ? block : 'hidden';

                            return (
                                <div
                                    key={`${blockIndex}-${rowIndex}-${cellIndex}`}
                                    className={`${styles.cell} ${styles[character]} ${styles[cellClass]}`}
                                />
                            );
                        })}
                    </span>
                );
            })}
        </motion.button>
    );
};

export default Shape;
