import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import { useDeviceStore } from '@/store/deviceStore';
import { handleUpdateDeviceActivatedStatus } from '@/services/global/globalHandleFunctions';
import { Device } from '@/services/global/types';
import type React from 'react';

const ToggleTabletStatus: React.FC = () => {
    const { red, blue, orange, green } = useDeviceStore.use.deviceStatus();
    return (
        <div>
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.green,
                        !green.isActivated
                    )
                }
                title={`${
                    green.isActivated ? 'Désactiver' : 'Activer'
                } la tablette verte`}
                description={`Cette action ${
                    green.isActivated ? 'désactive' : 'active'
                } la tablette verte.`}
                displayingCondition={true}
            />
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.orange,
                        !orange.isActivated
                    )
                }
                title={`${
                    orange.isActivated ? 'Désactiver' : 'Activer'
                } la tablette orange`}
                description={`Cette action ${
                    orange.isActivated ? 'désactive' : 'active'
                } la tablette orange.`}
                displayingCondition={true}
            />
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(
                        Device.blue,
                        !blue.isActivated
                    )
                }
                title={`${
                    blue.isActivated ? 'Désactiver' : 'Activer'
                } la tablette bleue`}
                description={`Cette action ${
                    blue.isActivated ? 'désactive' : 'active'
                } la tablette bleue.`}
                displayingCondition={true}
            />
            <MediatorAction
                handleFunction={() =>
                    handleUpdateDeviceActivatedStatus(Device.red, !red.isActivated)
                }
                title={`${
                    red.isActivated ? 'Désactiver' : 'Activer'
                } la tablette rouge`}
                description={`Cette action ${
                    red.isActivated ? 'désactive' : 'active'
                } la tablette rouge.`}
                displayingCondition={true}
            />
        </div>
    );
};

export default ToggleTabletStatus;
