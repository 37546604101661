import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// scss
import globalStyle from '@/assets/styles/app.module.scss';

// services
import { useGameStore } from '@/store/gameStore';

// components
import ActionButton, {
    type ActionType,
} from '@/features/devices/tablets/components/actionButton/ActionButton';
import ChooseIntensityEmotion from '@/features/devices/tablets/components/game1action2/ChooseIntensityEmotion';
import ChooseStimuli from '@/features/devices/tablets/components/game2Action/ChooseStimuli';
import ChooseTetris from '@/features/devices/tablets/components/game3/ChooseTetris';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

// datas
import RemoteTetris from '@/features/devices/tablets/components/game3/RemoteTetris';

import Vote from '@/features/devices/tablets/components/game5/Vote';
import ChooseItem from '@/features/devices/tablets/components/itemBox/ChooseItem';
import NextButtonScreen from '@/features/devices/tablets/components/screens/NextButtonScreen';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame2Store } from '@/store/games/game2Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGame4Store } from '@/store/games/game4Store';
import { useOverlayStore } from '@/store/overlayStore';
import { Device } from '@/services/global/types';
import { GAMESTATUS } from '@/types/games/types';
import useReSynchronizeDevice from '@/services/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';

const GreenActionSwitch: React.FC = () => {
    const { t } = useTranslation('global');
    useReSynchronizeDevice('green');
    const currentOverlayDescriptions = useOverlayStore.use.descriptions();
    const overlayType = useOverlayStore.use.overlayType();
    const currentGameStatus = useGameStore.use.gameStatus();

    const showCurrentOverlay = currentOverlayDescriptions.length > 0;
    const green = useDeviceStore.use.deviceStatus().green;
    const isConfirmSelectionButton = useGame2Store.use.isConfirmSelectionButton();
    const statusTetris = useGame3Store.use.statusTetris();

    const itemsSelectedGame4 = useGame4Store.use.itemsSelected();
    const itemsFoundGame4 = useGame4Store.use.itemsFound();
    const isBehaviorFound = itemsFoundGame4.find(
        (item) => item?.id === itemsSelectedGame4?.behavior?.emotionId
    );

    const isTabletInactive = !green.isActivated;

    const gameResult = useGameStore.use.gameResult();
    const deviceColor = Device.green;

    useEffect(() => {
        document.title = `🟢 ${green.label}`;
        emitSocketEvent('send_synchronize_device', { deviceColor: 'green' });
    }, [green.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.GAME1ACTION1:
                return <ChooseItem isSymbol />;
            case GAMESTATUS.GAME1ACTION2:
                return <ChooseIntensityEmotion intensity="strongEmotion" />;
            case GAMESTATUS.GAME2INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );
            case GAMESTATUS.GAME2ACTION: {
                return isConfirmSelectionButton ? (
                    <div className={globalStyle.containerButtons}>
                        <ActionButton
                            title={t('buttons.confirm')}
                            action="handleValidationGame2Action"
                            type="neutral"
                        />
                        <ActionButton
                            title={t('buttons.modify')}
                            action="handleIsConfirmSelectionButton"
                            type="neutral"
                        />
                    </div>
                ) : (
                    <ChooseStimuli type="sport" />
                );
            }
            case GAMESTATUS.GAME3ACTION:
                return (
                    <>
                        {statusTetris === 'start' ? (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.remotes.left.title`
                                    ),
                                    text: t(
                                        `${currentGameStatus}.remotes.left.description`
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <RemoteTetris type="left" />
                            </TabletScreen>
                        ) : (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.title`,
                                        { ns: 'actionContent' }
                                    ),
                                    text: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.text`,
                                        { ns: 'actionContent' }
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <ChooseTetris character="fanny" />
                            </TabletScreen>
                        )}
                    </>
                );
            case GAMESTATUS.GAME4ACTION:
                return (
                    <>
                        {showCurrentOverlay ? (
                            <TabletScreen
                                typeBackground="circle"
                                step="action"
                                deviceColor={deviceColor}
                            >
                                <ActionButton
                                    title={
                                        overlayType === 'success'
                                            ? t('buttons.next')
                                            : t('buttons.retry')
                                    }
                                    action={
                                        `${overlayType}Validation` as ActionType
                                    }
                                    type={overlayType}
                                />
                            </TabletScreen>
                        ) : (
                            <>
                                {isBehaviorFound ? (
                                    <TabletScreen
                                        header={{
                                            title: t('headers.inactive.title'),
                                            text: t('headers.inactive.text'),
                                        }}
                                        typeBackground="circle"
                                    />
                                ) : (
                                    <TabletScreen
                                        typeBackground="circle"
                                        step="action"
                                        deviceColor={deviceColor}
                                    >
                                        {gameResult === '' &&
                                            itemsSelectedGame4.code &&
                                            itemsSelectedGame4.code.length >= 2 && (
                                                <ActionButton
                                                    title={t('buttons.validate')}
                                                    action="handleValidationGame4"
                                                    type="neutral"
                                                />
                                            )}
                                    </TabletScreen>
                                )}
                            </>
                        )}
                    </>
                );
            case GAMESTATUS.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default GreenActionSwitch;
