import { useState } from 'react';

// scss
import globalStyle from '@/assets/styles/app.module.scss';

// components
import ItemBoxTablet from '@/features/devices/tablets/components/itemBox/ItemBoxTablet';
import TabletOverlay from '@/features/devices/tablets/components/overlay/TabletOverlay';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

// services
import { useGame1Store } from '@/store/games/game1Store';
import { type Device } from '@/services/global/types';
import type { Nullable } from '@/types/global/types';
import type { Emotion } from '@/types/games/types';

const ListEmotionToFound: React.FC = () => {
    const emotionsFound = useGame1Store.use.itemsFound() as Emotion[];

    const [overlayEmotionIndex, setOverlayEmotionIndex] =
        useState<Nullable<number>>(null);

    const handleCloseOverlay = () => {
        setOverlayEmotionIndex(null);
    };

    return (
        <>
            {overlayEmotionIndex !== null ? (
                <TabletOverlay
                    title={emotionsFound[overlayEmotionIndex].title}
                    description={emotionsFound[overlayEmotionIndex].description}
                    onClose={() => handleCloseOverlay()}
                />
            ) : (
                <TabletScreen
                    step="action"
                    deviceColor={'blue' as Device}
                    typeBackground="banner"
                >
                    <div className={globalStyle.itemBoxContainer}>
                        {emotionsFound.map(
                            (emotion, index) =>
                                emotion && (
                                    <ItemBoxTablet
                                        key={emotion.slug}
                                        item={emotion}
                                        handleClick={() =>
                                            setOverlayEmotionIndex(index)
                                        }
                                        showName
                                        isSymbol={false}
                                    />
                                )
                        )}
                    </div>
                </TabletScreen>
            )}
        </>
    );
};

export default ListEmotionToFound;
