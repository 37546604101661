import styles from '@/features/devices/tv/components/games/game1/game1Action1.module.scss';
import { useTranslation } from 'react-i18next';

import ItemBoxTv from '@/features/devices/tv/components/itemBox/ItemBoxTv';
import { useGameStore } from '@/store/gameStore';

import { useGame1Store } from '@/store/games/game1Store';
import { handlePatternImage } from '@/services/global/globalHandleFunctions';

const Game1Action1: React.FC = () => {
    const itemsSelected = useGame1Store.use.itemsSelected().action1;
    const gameResult = useGameStore.use.gameResult();
    const currentGameStatus = useGameStore.use.gameStatus();

    const { t } = useTranslation('actionContent');

    return (
        <div className={styles.mission1Action1Container}>
            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />

            <ItemBoxTv
                image={itemsSelected?.emotion?.image ?? ''}
                text={t(`${currentGameStatus}.tv.bodyContent.emotion`)}
                key={itemsSelected?.emotion?.slug}
                gameResult={gameResult}
            />

            <ItemBoxTv
                image={itemsSelected?.symbol?.image ?? ''}
                text={t(`${currentGameStatus}.tv.bodyContent.symbol`)}
                key={itemsSelected?.symbol?.title}
                gameResult={gameResult}
            />

            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />
        </div>
    );
};

export default Game1Action1;
