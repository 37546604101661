import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// components
import ActionButton from '@/features/devices/tablets/components/actionButton/ActionButton';
import ChooseIntensityEmotion from '@/features/devices/tablets/components/game1action2/ChooseIntensityEmotion';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

// reducers
import { useGameStore } from '@/store/gameStore';

// assets
import ChooseStimuli from '@/features/devices/tablets/components/game2Action/ChooseStimuli';
import ChooseTetris from '@/features/devices/tablets/components/game3/ChooseTetris';
import RemoteTetris from '@/features/devices/tablets/components/game3/RemoteTetris';
import OrangeGame1Action1 from '@/features/devices/tablets/orange/games/game1action1/OrangeGame1Action1';
import ChooseBehavior from '@/features/devices/tablets/orange/games/game4/ChooseBehavior';
import globalStyle from '@/assets/styles/app.module.scss';

import Vote from '@/features/devices/tablets/components/game5/Vote';
import NextButtonScreen from '@/features/devices/tablets/components/screens/NextButtonScreen';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame3Store } from '@/store/games/game3Store';
import { Device } from '@/services/global/types';
import { GAMESTATUS } from '@/types/games/types';
import useReSynchronizeDevice from '@/services/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';

const OrangeActionSwitch: React.FC = () => {
    const { t } = useTranslation('global');
    useReSynchronizeDevice('orange');
    const currentGameStatus = useGameStore.use.gameStatus();
    const orange = useDeviceStore.use.deviceStatus().orange;
    const statusTetris = useGame3Store.use.statusTetris();
    const deviceColor = Device.orange;

    const isTabletInactive = !orange.isActivated;

    useEffect(() => {
        document.title = `🟠 ${orange.label}`;
        emitSocketEvent('send_synchronize_device', { deviceColor: 'orange' });
    }, [orange.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.WELCOME_SCREEN:
                return (
                    <TabletScreen
                        typeBackground="circle"
                        header={{
                            title: t('headers.welcome.title'),
                            text: t('headers.welcome.text'),
                        }}
                    >
                        <ActionButton
                            title={t('headers.welcome.buttonText')}
                            action="goToNextGameStatus"
                            type="neutral"
                        />
                    </TabletScreen>
                );
            case GAMESTATUS.INTRODUCTION:
            case GAMESTATUS.GAME1ACTION1INTRODUCTION:
            case GAMESTATUS.GAME4INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );

            case GAMESTATUS.GAME1ACTION1:
                return <OrangeGame1Action1 />;
            case GAMESTATUS.GAME1ACTION2:
                return <ChooseIntensityEmotion intensity="weakEmotion" />;
            case GAMESTATUS.GAME2ACTION:
                return <ChooseStimuli type="friends" />;

            case GAMESTATUS.GAME3ACTION:
                return (
                    <>
                        {statusTetris === 'start' ? (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.remotes.rotation.title`
                                    ),
                                    text: t(
                                        `${currentGameStatus}.remotes.rotation.title`
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <RemoteTetris type="up" />
                            </TabletScreen>
                        ) : (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.title`,
                                        { ns: 'actionContent' }
                                    ),
                                    text: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.text`,
                                        { ns: 'actionContent' }
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <ChooseTetris character="paul" />
                            </TabletScreen>
                        )}
                    </>
                );
            case GAMESTATUS.GAME4ACTION:
                return <ChooseBehavior />;
            case GAMESTATUS.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default OrangeActionSwitch;
