import {
    resetItemsFoundGame1,
    resetItemsSelectedGame1,
    setItemsSelectedGame1,
    type GameOneAction1,
} from '@/store/games/game1Store';
import {
    resetItemsFoundGame2,
    resetItemsSelectedGame2,
    setEmotionToItemsSelected,
    setItemsSelectedGame2,
} from '@/store/games/game2Store';
import {
    resetItemsFoundGame3,
    resetItemsSelectedGame3,
    setItemsSelectedGame3,
} from '@/store/games/game3Store';
import {
    resetItemsFoundGame4,
    resetItemsSelectedGame4,
    setItemsSelectedGame4,
} from '@/store/games/game4Store';
import {
    resetItemsSelectedGame5,
    setItemsSelectedGame5,
} from '@/store/games/game5Store';
import type { GameOneAction2 } from '@/types/games/game1/types';
import type { Game2Action } from '@/types/games/game2/types';
import type { Game3Action } from '@/types/games/game3/types';
import type { Game4Action } from '@/types/games/game4/types';
import type { ResponseSituation, Game5Action } from '@/types/games/game5/types';
import {
    GAMESTATUS,
    type _Symbol,
    type Behavior,
    type Emotion,
    type GameActionsKeys,
    type GameItem,
    type GameStatus,
    type Stimulus,
} from '@/types/games/types';

export function updateSelectedItemsByGameStatus(data: {
    gameStatus: GameStatus;
    item: GameItem;
    itemKey: GameActionsKeys;
}): void {
    switch (data.gameStatus) {
        case GAMESTATUS.GAME1ACTION1:
            setItemsSelectedGame1('action1', {
                item: data.item as Emotion | _Symbol,
                itemKey: data.itemKey as keyof GameOneAction1,
            });
            break;
        case GAMESTATUS.GAME1ACTION2:
            setItemsSelectedGame1('action2', {
                item: data.item as Emotion,
                itemKey: data.itemKey as keyof GameOneAction2,
            });
            break;
        case GAMESTATUS.GAME2ACTION:
            setItemsSelectedGame2({
                item: data.item as Stimulus,
                itemKey: data.itemKey as keyof Game2Action,
            });
            break;
        case GAMESTATUS.GAME3ACTION:
            setItemsSelectedGame3({
                item: data.item as Emotion,
                itemKey: data.itemKey as keyof Game3Action,
            });
            break;
        case GAMESTATUS.GAME4ACTION:
            setItemsSelectedGame4({
                item: data.item as Behavior,
                itemKey: data.itemKey as keyof Game4Action,
            });
            break;
        case GAMESTATUS.GAME5ACTION:
            setItemsSelectedGame5({
                item: data.item as ResponseSituation,
                itemKey: data.itemKey as keyof Game5Action,
            });
            break;
        default:
            break;
    }
}

export function resetItemsSelectedByGameStatus(data: {
    gameStatus: GameStatus;
}): void {
    switch (data.gameStatus) {
        case GAMESTATUS.GAME1ACTION1:
        case GAMESTATUS.GAME1ACTION2:
            resetItemsSelectedGame1();
            break;
        case GAMESTATUS.GAME2ACTION:
            resetItemsSelectedGame2();
            setEmotionToItemsSelected();
            break;
        case GAMESTATUS.GAME3ACTION:
            resetItemsSelectedGame3();
            break;
        case GAMESTATUS.GAME4ACTION:
            resetItemsSelectedGame4();
            break;
        case GAMESTATUS.GAME5ACTION:
            resetItemsSelectedGame5();
            break;
        default:
            break;
    }
}

export function resetItemsFoundByGameStatus(data: {
    gameStatus: GameStatus;
}): void {
    switch (data.gameStatus) {
        case GAMESTATUS.GAME1ACTION1:
        case GAMESTATUS.GAME1ACTION2:
            resetItemsFoundGame1();
            break;
        case GAMESTATUS.GAME2ACTION:
            resetItemsFoundGame2();
            break;
        case GAMESTATUS.GAME3ACTION:
            resetItemsFoundGame3();
            break;
        case GAMESTATUS.GAME4ACTION:
            resetItemsFoundGame4();
            break;
        default:
            break;
    }
}
