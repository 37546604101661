import MediatorScreen from '@/features/devices/tablets/mediator/MediatorScreen';
import PreConnectionMediatorScreen from '@/features/devices/tablets/mediator/PreConnectionMediatorScreen';
import globalStyle from '@/assets/styles/app.module.scss';

import { useDeviceStore } from '@/store/deviceStore';
import { handleStartGame } from '@/services/global/globalHandleFunctions';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { useEffect } from 'react';

const MediatorTablet: React.FC = () => {
    const { mediator, blue, orange, red, green } =
        useDeviceStore.use.deviceStatus();

    useEffect(() => {
        document.title = `⭐ ${mediator.label}`;
        emitSocketEvent('send_synchronize_device', { deviceColor: 'mediator' });
    }, [mediator.label]);

    return (
        <div
            className={globalStyle.containerTablet}
            style={{
                overflow: 'scroll',
            }}
        >
            {!mediator.isConnected && (
                <PreConnectionMediatorScreen
                    deviceStatuses={[
                        { label: 'Bleu', status: blue.isConnected, color: 'blue' },
                        {
                            label: 'Orange',
                            status: orange.isConnected,
                            color: 'orange',
                        },
                        { label: 'Rouge', status: red.isConnected, color: 'red' },
                        {
                            label: 'Verte',
                            status: green.isConnected,
                            color: 'green',
                        },
                    ]}
                    handleConnect={() => {
                        handleStartGame('mediator');
                    }}
                />
            )}
            {mediator.isConnected && <MediatorScreen />}
        </div>
    );
};

export default MediatorTablet;
