import { PatternHorizontalSmall } from '@/assets/images/tablets';
import styles from '@/features/devices/tablets/components/chooseAnswer/chooseAnswer.module.scss';
import { useGame5Store } from '@/store/games/game5Store';
import { type DeviceColor } from '@/services/global/types';
import { Fragment } from 'react';

interface ChooseAnswerProps {
    responses: { id: number; text: string }[];
    deviceColor: DeviceColor;
    status: '' | 'success' | 'error';
}

const ChooseAnswer: React.FC<ChooseAnswerProps> = ({
    responses,
    deviceColor,
    status,
}) => {
    const itemsSelected = useGame5Store.use.itemsSelected();
    return (
        <div
            className={`${styles.chooseAnswerContainer} ${
                status ? styles[status] : undefined
            }`}
        >
            {responses?.map((response) => {
                const isLastResponse = response.id === responses.length;
                const isNotSelected =
                    itemsSelected[deviceColor as keyof typeof itemsSelected]?.id !==
                    response.id;
                return (
                    <Fragment key={response.id}>
                        <p
                            className={
                                isNotSelected ? styles.notSelected : undefined
                            }
                        >
                            [{response.id}] {response.text}
                        </p>
                        {!isLastResponse && (
                            <img src={PatternHorizontalSmall} alt="" />
                        )}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default ChooseAnswer;
