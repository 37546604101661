import styles from '@/features/devices/tv/components/games/game4/game4Action.module.scss';

import CarouselCharacter from '@/features/devices/tv/components/games/game4/CarouselCharacter';
import AudioComponent from '@/features/reusablecomponents/global/AudioComponent';
import { useGameStore } from '@/store/gameStore';
import { useGame4Store } from '@/store/games/game4Store';

const Game4Action: React.FC = () => {
    const itemsSelected = useGame4Store.use.itemsSelected();
    const itemsFound = useGame4Store.use.itemsFound();

    const gameResult = useGameStore.use.gameResult();

    const handleStyleGameResult = () => {
        if (gameResult === 'error') return styles.error;
        if (gameResult === 'success') return styles.success;
        else return styles.neutral;
    };

    const isBehaviorFound = itemsFound.find(
        (item) => item?.id === itemsSelected?.behavior?.emotionId
    );

    return (
        <div className={styles.game4ActionContainer}>
            {itemsSelected.behavior?.audio && !isBehaviorFound && (
                <AudioComponent src={itemsSelected.behavior.audio} />
            )}
            <div className={handleStyleGameResult()}>
                <div className={styles.borderLeftBox}>
                    <h1>{itemsSelected?.behavior?.name}</h1>
                    <p>{itemsSelected?.behavior?.behavior}</p>
                </div>
            </div>
            <CarouselCharacter />
        </div>
    );
};

export default Game4Action;
