import styles from '@/features/reusablecomponents/global/errorAndSuccessBackground/errorAndSuccessBackground.module.scss';

interface ErrorAndSuccessBackgroundProps {
    type: 'success' | 'error';
    device?: 'tv' | 'tablet';
}

const NB_STRIPES_TV = 10;
const NB_STRIPES_TABLET = 7;

const ErrorAndSuccessBackground: React.FC<ErrorAndSuccessBackgroundProps> = ({
    type,
    device,
}) => {
    const stripesNumber = device === 'tv' ? NB_STRIPES_TV : NB_STRIPES_TABLET;
    return (
        <>
            {/* Create NB_STRIPES_[DEVICE] stripes to process the animation */}
            {Array.from(Array(stripesNumber).keys()).map((_, index) => (
                <div
                    key={index}
                    className={`${styles[`${device}-${type}`]} ${
                        styles[`${device}-stripes-animation`]
                    }`}
                />
            ))}
        </>
    );
};

export default ErrorAndSuccessBackground;
