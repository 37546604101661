// services

// components
import ItemBox from '@/features/devices/tv/components/itemBox/ItemBox';

import StrongWeakEmotion from '@/features/devices/tablets/components/game1action2/StrongWeakEmotion';
import styles from '@/features/devices/tv/components/games/game1/game1Action1.module.scss';
import { useGameStore } from '@/store/gameStore';

import { useGame1Store } from '@/store/games/game1Store';
import { handlePatternImage } from '@/services/global/globalHandleFunctions';
import { useTranslation } from 'react-i18next';

const Game1Action2: React.FC = () => {
    const itemsSelected = useGame1Store.use.itemsSelected().action2;
    const gameResult = useGameStore.use.gameResult();
    const { t } = useTranslation('actionContent');
    const currentGameStatus = useGameStore.use.gameStatus();

    return (
        <div className={styles.mission1Action1Container}>
            <StrongWeakEmotion
                item={itemsSelected?.weakEmotion}
                isDisabled={false}
                description={t(`${currentGameStatus}.tv.bodyContent.weakIntensity`)}
                gameResult={gameResult}
            />
            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />
            <ItemBox
                image={itemsSelected?.emotion?.image ?? ''}
                text={
                    itemsSelected?.emotion?.title ??
                    t(`${currentGameStatus}.tv.bodyContent.title`)
                }
                gameResult={gameResult}
                additionalStyle={{ marginRight: 0 }}
                key={itemsSelected?.emotion?.slug}
            />
            <img src={handlePatternImage(gameResult)} alt="Circuit pattern" />
            <StrongWeakEmotion
                item={itemsSelected?.strongEmotion}
                isDisabled={false}
                description={t(
                    `${currentGameStatus}.tv.bodyContent.strongIntensity`
                )}
                gameResult={gameResult}
            />
        </div>
    );
};

export default Game1Action2;
