import styles from '@/features/devices/tablets/mediator/mediatorAction.module.scss';
import {
    handleCarouselClick,
    handleMouse,
} from '@/services/device/deviceHandleFunctions';
import type React from 'react';

import { useGameStore } from '@/store/gameStore';

import { useGame4Store } from '@/store/games/game4Store';
import type { ItemsSelected } from '@/types/games/types';

interface ButtonsDirectionsProps {
    remoteName: 'tetris' | 'carousel';
    itemsSelected?: ItemsSelected | undefined;
}
const ButtonsDirections: React.FC<ButtonsDirectionsProps> = ({
    remoteName,
    itemsSelected,
}) => {
    const gameStatus = useGameStore.use.gameStatus();

    const { rightIndex, centerIndex, leftIndex } =
        useGame4Store.use.carouselValues();
    const listBehaviors = useGame4Store.use.listBehaviors();

    const buttonConfigurations = {
        tetris: [
            { type: 'left', label: 'Gauche' },
            { type: 'up', label: 'Rotation' },
            { type: 'right', label: 'Droite' },
            // disable speed until bug is fixed
            // { type: 'down', label: 'Speed' },
        ],
        carousel: [
            { type: 'previous', label: 'Gauche' },
            { type: 'next', label: 'Droite' },
        ],
    };
    return (
        <div className={styles.mediatorAction}>
            {buttonConfigurations[remoteName].map((button) => (
                <button
                    key={button.type}
                    className={styles.tertiary}
                    onMouseDown={
                        remoteName === 'tetris'
                            ? () => handleMouse('down', button.type)
                            : () =>
                                  handleCarouselClick(
                                      button.type,
                                      itemsSelected as ItemsSelected,
                                      gameStatus,
                                      listBehaviors,
                                      leftIndex,
                                      centerIndex,
                                      rightIndex
                                  )
                    }
                    onMouseUp={
                        remoteName === 'tetris'
                            ? () => handleMouse('up', button.type)
                            : undefined
                    }
                >
                    {button.label}
                </button>
            ))}
        </div>
    );
};

export default ButtonsDirections;
