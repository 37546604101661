import Shape from '@/features/devices/tv/components/games/game3/shape/Shape';
import styles from '@/features/devices/tv/components/games/game3/upcoming/upcomingBlocks.module.scss';
import { useGameStore } from '@/store/gameStore';
import { SHAPES, type Block } from '@/services/global/types';

import { useTranslation } from 'react-i18next';

interface UpcomingBlocksProps {
    upcomingBlocks: Block[];
}

const UpcomingBlocks: React.FC<UpcomingBlocksProps> = ({ upcomingBlocks }) => {
    const gameStatus = useGameStore.use.gameStatus();
    const { t } = useTranslation('actionContent');

    return (
        <div className={styles.upcomingBlocksContainer}>
            <div>
                {upcomingBlocks.map((block, index) => {
                    const shape = SHAPES[block.tetris].shape.filter((row) =>
                        row.some((cell) => cell)
                    );

                    return (
                        <Shape
                            shape={shape}
                            blockIndex={index}
                            block={block.tetris}
                            character={block.character}
                            isUpcoming
                            key={index}
                        />
                    );
                })}
            </div>
            <p>{t(`${gameStatus}.tv.bodyContent.stock`)}</p>
        </div>
    );
};

export default UpcomingBlocks;
