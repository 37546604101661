// scss && assets
import { ShapeStimulusColored } from '@/assets/images/tv';
import styles from '@/features/devices/tv/components/games/game2/game2Action.module.scss';
import { useGame2Store } from '@/store/games/game2Store';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import type { EnvironmentsKey } from '@/types/games/game2/types';

const variants = {
    family: {
        x: 100,
        y: -100,
        opacity: 0,
    },
    sport: {
        x: -100,
        y: -100,
        opacity: 0,
    },
    friends: {
        x: 100,
        y: 100,
        opacity: 0,
    },
    work: {
        x: -100,
        y: 100,
        opacity: 0,
    },

    animate: {
        x: 0,
        y: 0,
        opacity: 1,
        transition: {
            duration: 1,
            ease: 'easeInOut',
        },
    },
};

const Mission2Action: React.FC = () => {
    const itemsSelected = useGame2Store.use.itemsSelected();
    const keyItem: string[] = Object.keys(itemsSelected || {});

    return (
        <div className={styles.mission2ActionContainer}>
            <img src={ShapeStimulusColored} alt="design" />
            <div
                className={`${styles.headEmotionRoundContainer} ${
                    styles[itemsSelected?.emotion?.slug ?? '']
                }`}
            >
                <img src={itemsSelected?.emotion?.image} alt="icône emotion" />
            </div>
            <div className={styles.stimuliContainer}>
                {keyItem.map((stimulusSelected: string) => {
                    const selectedStimulus =
                        itemsSelected &&
                        itemsSelected[stimulusSelected as keyof EnvironmentsKey];
                    return (
                        <Fragment key={stimulusSelected}>
                            {selectedStimulus && (
                                <div
                                    className={`${styles.stimuliIcon} ${styles[stimulusSelected]}`}
                                >
                                    {selectedStimulus.picto && (
                                        <motion.img
                                            src={selectedStimulus.picto}
                                            alt={selectedStimulus.slug}
                                            variants={variants}
                                            initial={stimulusSelected}
                                            animate="animate"
                                        />
                                    )}
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            </div>
        </div>
    );
};

export default Mission2Action;
