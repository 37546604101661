import {
    CircuitPattern,
    CircuitPatternError,
    CircuitPatternSuccess,
} from '@/assets/images/tv';
import SocketService from '@/services/SocketService';

import { resetDevice, setDeviceConnectedStatus } from '@/store/deviceStore';
import { emitSocketEvent } from '@/services/global/globalUtils';

import { resetGame } from '@/store/gameStore';
import {
    resetItemsFoundGame1,
    resetItemsSelectedGame1,
} from '@/store/games/game1Store';
import {
    resetItemsFoundGame2,
    resetItemsSelectedGame2,
} from '@/store/games/game2Store';
import {
    resetItemsFoundGame3,
    resetItemsSelectedGame3,
} from '@/store/games/game3Store';
import {
    resetItemsFoundGame4,
    resetItemsSelectedGame4,
} from '@/store/games/game4Store';
import { resetItemsSelectedGame5 } from '@/store/games/game5Store';
import { resetOverlays } from '@/store/overlayStore';
import { type Socket } from 'socket.io-client';
import type { GameStatus } from '@/types/games/types';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

const getSocket = (): Socket => {
    return SocketService.getInstance().getSocket();
};

export const handleReset = (): void => {
    emitSocketEvent('send_reset', {});
};

export const resetProtocol = (): void => {
    resetDevice();
    resetGame();
    resetOverlays();
    resetItemsFoundGame1();
    resetItemsFoundGame2();
    resetItemsFoundGame3();
    resetItemsFoundGame4();
    resetItemsSelectedGame1();
    resetItemsSelectedGame2();
    resetItemsSelectedGame3();
    resetItemsSelectedGame4();
    resetItemsSelectedGame5();
};

export const handleInitGameDevMode = (
    device: DeviceColorWithTvAndMediator,
    roomName: string
): void => {
    if (roomName === '') return;

    setDeviceConnectedStatus(device, true);
    emitSocketEvent('send_join_room', { device });
};

export const handleSendGameStatus = (gameStatus: GameStatus): void => {
    emitSocketEvent('send_game_status', {
        status: gameStatus,
    });
};

export const handleStartGame = (color: DeviceColorWithTvAndMediator): void => {
    emitSocketEvent('send_start_game', { color });
};

export const handleUpdateDeviceActivatedStatus = (
    deviceColor: DeviceColorWithTvAndMediator,
    status: boolean
): void => {
    emitSocketEvent('send_device_activated_status', { deviceColor, status });
};

export const handleSendIsAudioPlaying = (
    isAudioPlaying: boolean,
    roomName: string
): void => {
    const socket = getSocket();
    const responsesSocket = {
        roomName,
        isAudioPlaying,
    };
    socket?.emit('send_is_audio_playing', responsesSocket);
};

export const playSound = (audio: string): void => {
    const sound = new Audio(audio);
    sound.play();
};

export const handlePatternImage = (
    gameResult: 'error' | 'success' | ''
): string => {
    if (gameResult === 'error') return CircuitPatternError;
    if (gameResult === 'success') return CircuitPatternSuccess;
    return CircuitPattern;
};

export const handleGameStatusToString = (gameStatus: GameStatus): string => {
    switch (gameStatus) {
        case 'GAME1ACTION1':
            return 'Game1Action1';
        case 'GAME1ACTION2':
            return 'Game1Action2';
        case 'GAME2ACTION':
            return 'Game2';
        case 'GAME3ACTION':
            return 'Game3';
        case 'GAME4ACTION':
            return 'Game4';
        case 'GAME5ACTION':
            return 'Game5';
        default:
            return '';
    }
};
