import { useEffect } from 'react';

// scss
import globalStyle from '@/assets/styles/app.module.scss';

// components
import ListEmotionToFound from '@/features/devices/tablets/blue/games/game1Action1/ListEmotionToFound';
import ChooseCode from '@/features/devices/tablets/blue/games/game4/ChooseCode';
import ChooseStimuli from '@/features/devices/tablets/components/game2Action/ChooseStimuli';
import ChooseTetris from '@/features/devices/tablets/components/game3/ChooseTetris';
import RemoteTetris from '@/features/devices/tablets/components/game3/RemoteTetris';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

import Vote from '@/features/devices/tablets/components/game5/Vote';
import ChooseItem from '@/features/devices/tablets/components/itemBox/ChooseItem';
import NextButtonScreen from '@/features/devices/tablets/components/screens/NextButtonScreen';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame3Store } from '@/store/games/game3Store';
import { useGame4Store } from '@/store/games/game4Store';
import { useGameStore } from '@/store/gameStore';
import { Device } from '@/services/global/types';
import { useTranslation } from 'react-i18next';
import { GAMESTATUS } from '@/types/games/types';
import useReSynchronizeDevice from '@/services/hooks/useReSynchronizeDevice';
import { emitSocketEvent } from '@/services/global/globalUtils';

const BlueActionSwitch: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const blue = useDeviceStore.use.deviceStatus().blue;
    const statusTetris = useGame3Store.use.statusTetris();
    const { t } = useTranslation(['global', 'actionContent']);

    useReSynchronizeDevice('blue');

    const isTabletInactive = !blue.isActivated;
    const deviceColor = Device.blue;

    // game4 handle display of Digit Keyboard
    const itemsSelectedGame4 = useGame4Store.use.itemsSelected();
    const itemsFoundGame4 = useGame4Store.use.itemsFound();
    const isBehaviorFound = itemsFoundGame4.find(
        (item) => item?.id === itemsSelectedGame4?.behavior?.emotionId
    );

    useEffect(() => {
        document.title = `🔵 ${blue.label}`;
        emitSocketEvent('send_synchronize_device', { deviceColor: 'blue' });
    }, [blue.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.GAME1ACTION1:
                return <ListEmotionToFound />;
            case GAMESTATUS.GAME1ACTION2:
                return <ChooseItem />;
            case GAMESTATUS.GAME2ACTION:
                return <ChooseStimuli type="work" />;
            case GAMESTATUS.GAME3INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );
            case GAMESTATUS.GAME3ACTION:
                return (
                    <>
                        {statusTetris === 'start' ? (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.remotes.right.title`
                                    ),
                                    text: t(
                                        `${currentGameStatus}.remotes.right.description`
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <RemoteTetris type="right" />
                            </TabletScreen>
                        ) : (
                            <TabletScreen
                                header={{
                                    title: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.title`,
                                        { ns: 'actionContent' }
                                    ),
                                    text: t(
                                        `${currentGameStatus}.${deviceColor}.headerContent.text`,
                                        { ns: 'actionContent' }
                                    ),
                                }}
                                typeBackground="circle"
                            >
                                <ChooseTetris character="eric" />
                            </TabletScreen>
                        )}
                    </>
                );
            case GAMESTATUS.GAME4ACTION:
                if (isBehaviorFound) {
                    return (
                        <TabletScreen
                            header={{
                                title: t('headers.inactive.title'),
                                text: t('headers.inactive.text'),
                            }}
                            typeBackground="circle"
                        />
                    );
                } else {
                    return <ChooseCode />;
                }
            case GAMESTATUS.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default BlueActionSwitch;
