import type React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// scss
import globalStyle from '@/assets/styles/app.module.scss';

// components
import ActionButton, {
    type ActionType,
} from '@/features/devices/tablets/components/actionButton/ActionButton';
import ChooseStimuli from '@/features/devices/tablets/components/game2Action/ChooseStimuli';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';
import ChooseGlobalFormTetris from '@/features/devices/tablets/red/games/game3/ChooseGlobalFormTetris';

import Vote from '@/features/devices/tablets/components/game5/Vote';
import ChooseItem from '@/features/devices/tablets/components/itemBox/ChooseItem';
import NextButtonScreen from '@/features/devices/tablets/components/screens/NextButtonScreen';
import { useDeviceStore } from '@/store/deviceStore';
import { useGame1Store } from '@/store/games/game1Store';
import { useGame3Store } from '@/store/games/game3Store';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import { emitSocketEvent } from '@/services/global/globalUtils';
import { Device } from '@/services/global/types';
import useReSynchronizeDevice from '@/services/hooks/useReSynchronizeDevice';
import { GAMESTATUS } from '@/types/games/types';

const RedActionSwitch: React.FC = () => {
    const currentGameStatus = useGameStore.use.gameStatus();
    const overlayType = useOverlayStore.use.overlayType();
    const currentOverlayDescriptions = useOverlayStore.use.descriptions();
    const red = useDeviceStore.use.deviceStatus().red;
    const itemsSelectedGame1Action2 = useGame1Store.use.itemsSelected().action2;
    const gameResult = useGameStore.use.gameResult();
    const statusTetris = useGame3Store.use.statusTetris();
    const { t } = useTranslation('global');

    useReSynchronizeDevice('red');

    const isTabletInactive = !red.isActivated;
    const showCurrentOverlay = currentOverlayDescriptions?.length > 0;
    const deviceColor = Device.red;

    useEffect(() => {
        document.title = `🔴 ${red.label}`;
        emitSocketEvent('send_synchronize_device', { deviceColor: 'red' });
    }, [red.label]);

    const handleRender = () => {
        switch (currentGameStatus) {
            case GAMESTATUS.GAME1ACTION2INTRODUCTION:
            case GAMESTATUS.GAME5INTRODUCTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextIntroductionOverlay"
                    />
                );
            case GAMESTATUS.GAME1ACTION1:
                return <ChooseItem />;
            case GAMESTATUS.GAME1ACTION2: {
                return (
                    <TabletScreen
                        typeBackground="circle"
                        step="action"
                        deviceColor={deviceColor}
                    >
                        {showCurrentOverlay ? (
                            <ActionButton
                                title={
                                    overlayType === 'success'
                                        ? t('buttons.next')
                                        : t('buttons.retry')
                                }
                                action={`${overlayType}Validation` as ActionType}
                                type={overlayType}
                            />
                        ) : (
                            itemsSelectedGame1Action2?.emotion &&
                            itemsSelectedGame1Action2?.weakEmotion &&
                            itemsSelectedGame1Action2?.strongEmotion &&
                            gameResult === '' && (
                                <ActionButton
                                    title={t('buttons.validate')}
                                    action="handleValidationGame1Action2"
                                    type="neutral"
                                />
                            )
                        )}
                    </TabletScreen>
                );
            }
            case GAMESTATUS.GAME1ACTION2PREVENTION:
            case GAMESTATUS.GAME2PREVENTION:
            case GAMESTATUS.GAME3PREVENTION:
            case GAMESTATUS.GAME4PREVENTION:
            case GAMESTATUS.GAME5PREVENTION:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextPreventOverlay"
                    />
                );

            case GAMESTATUS.GAME1UNLOCK:
            case GAMESTATUS.GAME2UNLOCK:
            case GAMESTATUS.GAME3UNLOCK:
            case GAMESTATUS.GAME4UNLOCK:
            case GAMESTATUS.GAME5UNLOCK:
                return (
                    <NextButtonScreen
                        deviceColor={deviceColor}
                        action="goToNextGameStatus"
                    />
                );

            case GAMESTATUS.GAME2ACTION: {
                return (
                    <>
                        {showCurrentOverlay ? (
                            <TabletScreen
                                typeBackground="circle"
                                step="action"
                                deviceColor={deviceColor}
                            >
                                <ActionButton
                                    title={
                                        overlayType === 'success'
                                            ? t('buttons.next')
                                            : t('buttons.retry')
                                    }
                                    action={
                                        `${overlayType}Validation` as ActionType
                                    }
                                    type={overlayType}
                                />
                            </TabletScreen>
                        ) : (
                            <ChooseStimuli type="family" />
                        )}
                    </>
                );
            }

            case GAMESTATUS.GAME3ACTION:
                if (statusTetris === 'start') {
                    // disable speed until bug is fixed
                    // return (
                    //     <TabletScreen
                    //         header={{
                    //             title: 'Commande',
                    //             text: `Déplacer rapidement vers le bas`,
                    //         }}
                    //         typeBackground="circle"
                    //     >
                    //         <RemoteTetris type="down" />
                    //     </TabletScreen>
                    // );
                    return (
                        <TabletScreen
                            typeBackground="circle"
                            header={{
                                title: t('headers.inactive.title'),
                                text: t('headers.inactive.text'),
                            }}
                        />
                    );
                }
                return <ChooseGlobalFormTetris />;

            case GAMESTATUS.GAME5ACTION:
                return (
                    <TabletScreen
                        step="action"
                        deviceColor={deviceColor}
                        typeBackground="empty"
                    >
                        <Vote deviceColor={deviceColor} />
                    </TabletScreen>
                );
            case GAMESTATUS.FINISHED:
                break;
        }
    };

    return (
        <div className={globalStyle.containerTablet}>
            {isTabletInactive ? (
                <TabletScreen
                    header={{
                        title: t('headers.inactive.title'),
                        text: t('headers.inactive.text'),
                    }}
                    typeBackground="circle"
                />
            ) : (
                <>{handleRender()}</>
            )}
        </div>
    );
};

export default RedActionSwitch;
