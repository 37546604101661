import Cell from '@/features/devices/tv/components/games/game3/Cell';
import styles from '@/features/devices/tv/components/games/game3/Game3Action.module.scss';
import boardStyles from '@/features/devices/tv/components/games/game3/board/board.module.scss';
import { useGameStore } from '@/store/gameStore';
import { useTranslation } from 'react-i18next';

import { useGame3Store } from '@/store/games/game3Store';
import { type Block, type BoardShape } from '@/services/global/types';

interface BoardProps {
    currentBoard: BoardShape;
}

const Board: React.FC<BoardProps> = ({ currentBoard }) => {
    const itemsSelected = useGame3Store.use.itemsSelected();
    const { t } = useTranslation('actionContent');
    const gameStatus = useGameStore.use.gameStatus();

    return (
        <div>
            <div className={styles.board}>
                {currentBoard?.map((row, rowIndex) => (
                    <div className={styles.row} key={`${rowIndex}`}>
                        {row.map((cell, colIndex) => (
                            <Cell
                                key={`${rowIndex}-${colIndex}`}
                                type={cell as Block}
                                colIndex={colIndex}
                                rowIndex={rowIndex}
                            />
                        ))}
                    </div>
                ))}
            </div>
            {itemsSelected.emotion ? (
                <p className={boardStyles.subtitle}>
                    {t(`${gameStatus}.tv.bodyContent.footer`)}{' '}
                    {itemsSelected?.emotion?.id}
                </p>
            ) : (
                <p className={boardStyles.subtitle}>
                    {t(`${gameStatus}.tv.bodyContent.choose`)}
                </p>
            )}
        </div>
    );
};

export default Board;
