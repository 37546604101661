import { useEffect, useState } from 'react';
import { BackgroundTimer } from '@/assets/images/tv';
import styles from '@/features/devices/tv/components/progressBarTimer/progressBarTimer.module.scss';

interface ProgressBarTimerProps {
    time: number;
    callback: (isTimerEnded: boolean) => void;
}

const WIDTH_START_PERCENT = 80;

const ProgressBarTimer: React.FC<ProgressBarTimerProps> = ({ time, callback }) => {
    const [countDown, setCountDown] = useState(time);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown((oldValue) => oldValue - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    if (countDown <= 0) {
        callback(false);
    }

    return (
        <div className={styles.progressBarTimerContainer}>
            <div />
            <img src={BackgroundTimer} alt="" />
            <p>{countDown}s</p>
            <div
                style={{
                    width: `${(countDown * WIDTH_START_PERCENT) / time}%`,
                    transition: 'width 1s linear',
                }}
            />
        </div>
    );
};

export default ProgressBarTimer;
