// scss
import globalStyle from '@/assets/styles/app.module.scss';

// components
import ItemBoxTablet from '@/features/devices/tablets/components/itemBox/ItemBoxTablet';
import TabletScreen from '@/features/devices/tablets/components/screens/TabletScreen';

// datas
import dataGlobal from '@/services/datas/global.json';

// utils
import {
    handleItemSelected,
    handleTabletStatus,
} from '@/services/device/deviceHandleFunctions';
import { Device } from '@/services/global/types';

// services
import { useGameStore } from '@/store/gameStore';
import { useItemsSelected } from '@/services/hooks/useItemSelected';
import { useTranslation } from 'react-i18next';
import {
    GAMESTATUS,
    type Emotion,
    type GameItem,
    type GameStatusActionWithoutGame5,
    type ItemsSelected,
} from '@/types/games/types';
import { useGame1Store } from '@/store/games/game1Store';
import { useEffect } from 'react';

interface ChooseItemProps {
    isSymbol?: boolean;
}

const ChooseItem: React.FC<ChooseItemProps> = ({ isSymbol = false }) => {
    const gameStatus = useGameStore.use.gameStatus();
    const deviceColor = Device.red;
    const { t } = useTranslation('global');

    const itemsSelectedGame1Action2 = useGame1Store.use.itemsSelected().action2;
    const itemsSelected = useItemsSelected(
        gameStatus as GameStatusActionWithoutGame5
    );

    const handleClickBox = (item: ItemsSelected) => {
        handleItemSelected(
            item as GameItem,
            isSymbol ? 'symbol' : 'emotion',
            gameStatus
        );
    };

    const items =
        gameStatus === GAMESTATUS.GAME1ACTION1 && isSymbol
            ? dataGlobal.GAME1ACTION1.symbol
            : (t('emotions', { returnObjects: true }) as Emotion[]);

    useEffect(() => {
        const shouldActivateRed =
            itemsSelectedGame1Action2 &&
            Object.hasOwn(itemsSelectedGame1Action2, 'emotion') &&
            Object.hasOwn(itemsSelectedGame1Action2, 'weakEmotion') &&
            Object.hasOwn(itemsSelectedGame1Action2, 'strongEmotion');

        if (gameStatus === GAMESTATUS.GAME1ACTION2) {
            handleTabletStatus({
                green: true,
                orange: true,
                red: shouldActivateRed ?? false,
                blue: true,
            });
        }
    }, [itemsSelectedGame1Action2, gameStatus]);

    return (
        <div className={globalStyle.itemBoxContainer}>
            <TabletScreen
                step="action"
                deviceColor={deviceColor}
                typeBackground="banner"
            >
                <div className={globalStyle.itemBoxContainer}>
                    {items.map((item) => (
                        <ItemBoxTablet
                            key={item.id}
                            item={item}
                            handleClick={() =>
                                handleClickBox(item as ItemsSelected)
                            }
                            isSelected={
                                isSymbol
                                    ? item.id === itemsSelected?.symbol?.id
                                    : item.id === itemsSelected?.emotion?.id
                            }
                            isSymbol={isSymbol}
                        />
                    ))}
                </div>
            </TabletScreen>
        </div>
    );
};

export default ChooseItem;
