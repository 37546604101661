import type { ResponseSituation } from '@/types/games/game5/types';
import type { GameStatus, HeaderContent, Stimulus } from '@/types/games/types';
import { type OverlayDescription } from '@/types/global/types';

export const isDevelopperMode = import.meta.env.VITE_DEVELOPPER_MODE === 'true';

export type ButtonsInformations = {
    title: string;
    color: string;
    action: string;
};

export type TypeBackground = 'circle' | 'banner' | 'empty';

export type DeviceColor = 'blue' | 'green' | 'orange' | 'red';

export type IntensityData = {
    [key: string]: string;
};

export enum BlockLetter {
    X = 'X',
    I = 'I',
    J = 'J',
    B = 'B',
    L = 'L',
    O = 'O',
    S = 'S',
    T = 'T',
    U = 'U',
    A = 'A',
}

export type Block = {
    tetris: BlockLetter;
    character: 'eric' | 'fanny' | 'paul';
};

export enum EmptyCell {
    Empty = 'Empty',
}

export type CellOptions = Block | EmptyCell;

export type BoardShape = CellOptions[][];

export type BlockShape = readonly boolean[][];

export type ShapesTetris = Record<BlockLetter, { shape: BlockShape }>;

export const SHAPES: ShapesTetris = {
    X: {
        shape: [
            [false, true, false],
            [true, true, true],
            [false, true, false],
        ],
    },
    I: {
        shape: [
            [false, false, false],
            [true, true, true],
            [false, false, false],
        ],
    },
    J: {
        shape: [
            [false, false],
            [true, false],
            [true, true],
        ],
    },
    B: {
        shape: [
            [true, false],
            [true, false],
        ],
    },
    L: {
        shape: [
            [false, false, false],
            [false, false, true],
            [true, true, true],
        ],
    },
    O: {
        shape: [
            [true, true],
            [true, true],
        ],
    },
    S: {
        shape: [
            [false, false, false],
            [false, true, true],
            [true, true, false],
        ],
    },
    T: {
        shape: [
            [false, false, false],
            [false, true, false],
            [true, true, true],
        ],
    },
    U: {
        shape: [
            [false, false, false],
            [true, false, true],
            [true, true, true],
        ],
    },
    A: {
        shape: [[true]],
    },
};

export type BoardState = {
    board: BoardShape;
    droppingRow: number;
    droppingColumn: number;
    droppingBlock: Block;
    droppingShape: BlockShape;
};

export type Disagrement = {
    text: string;
    audio: string;
};

export const imageVariants = {
    initial: {
        y: 150,
        scale: 0,
    },
    animate: {
        y: 0,
        scale: 1,
        transition: {
            duration: 0.5,
            ease: 'easeInOut',
        },
    },
};

export enum Device {
    red = 'red',
    blue = 'blue',
    green = 'green',
    orange = 'orange',
    tv = 'tv',
    mediator = 'mediator',
}

export type ActionContentJson = {
    [key in GameStatus]: {
        [key in Device]: {
            headerContent: HeaderContent;
            footerContent?: HeaderContent;
        };
    };
};

export type GameStatusOverlayContent = {
    [outerKey in GameStatus]?: {
        tv: OverlayDescription[];
    } & { [innerKey in Device]?: { headerContent?: HeaderContent } };
};

export type Behavior = {
    id: number;
    slug: string;
    name: string;
    image: string;
    title: string;
    behavior: string;
    emotionId: number;
    behaviorFound: boolean;
    audio: string;
};
export type Message = {
    [key in GameStatus]: OverlayDescription[];
};

export type EmotionSecondary = {
    id: number;
    slug: string;
    title: string;
    description: string;
    image: string;
};

export type Emotion = {
    id: number;
    slug: string;
    title: string;
    description: string;
    image: string;
    successMessage: Message;
    errorMessage: Message;
};

export type _Symbol = {
    id: number;
    slug: string;
    title: string;
    image: string;
};

export type Character = {
    id: number;
    name: string;
    slug: string;
};

export type OverlayType = 'neutral' | 'success' | 'error' | 'unlock';

export interface ItemSelected {
    strongEmotion?: Emotion;
    weakEmotion?: Emotion;
    emotion?: Emotion;
    symbol?: _Symbol;
    work?: Stimulus;
    family?: Stimulus;
    sport?: Stimulus;
    friends?: Stimulus;
    character?: Character;
    tetrispaul?: string;
    tetrisfanny?: string;
    tetriseric?: string;
    behavior?: Behavior;
    code?: string;
    red?: ResponseSituation;
    green?: ResponseSituation;
    blue?: ResponseSituation;
    orange?: ResponseSituation;
}

export type itemsSelectedGame1Action1 = Pick<ItemSelected, 'emotion' | 'symbol'>;
export type itemsSelectedGame1Action2 = Pick<
    ItemSelected,
    'emotion' | 'weakEmotion' | 'strongEmotion'
>;
export type itemsSelectedGame2Action = Pick<
    ItemSelected,
    'emotion' | 'work' | 'friends' | 'family' | 'sport'
>;

export type itemsSelectedGame3Action = Pick<
    ItemSelected,
    'character' | 'emotion' | 'tetriseric' | 'tetrispaul' | 'tetrisfanny'
>;
export type itemsSelectedGame4Action = Pick<ItemSelected, 'behavior' | 'code'>;
export type itemsSelectedGame5Action = Pick<
    ItemSelected,
    'red' | 'green' | 'blue' | 'orange'
>;

export type ItemFound = {
    behavior: Behavior;
    emotion: Emotion;
};
