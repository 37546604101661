import i18n from '@/i18n';
import headerDatas from '@/services/datas/actionContent.json';
import { deepCopy } from '@/services/global/globalUtils';
import {
    type Carousel,
    type CarouselValues,
    type Game4Action,
} from '@/types/games/game4/types';
import { GAMESTATUS, type Behavior } from '@/types/games/types';
import { create } from 'zustand';
import createSelectors from '../selectors';
import type { Game4Store } from '@/types/games/game4/types';

interface Game4ItemsSelected {
    item: Behavior | string;
    itemKey: keyof Game4Action;
}

const behaviors = i18n.t('GAME4ACTION.behaviors', {
    returnObjects: true,
}) as Behavior[];

export const INITIAL_GAME4_STORE: Game4Store = {
    itemsSelected: { behavior: behaviors[0] } as Game4Action,
    listBehaviors: behaviors,
    itemsFound: Array(4).fill(null),
    headerContent: headerDatas[GAMESTATUS.GAME4ACTION].tv.headerContent,
    carouselValues: {
        flowDirection: 0,
        centerIndex: 0,
        leftIndex: behaviors.length - 1,
        rightIndex: 1,
    },
};

const useGame4StoreBase = create<Game4Store>(() => deepCopy(INITIAL_GAME4_STORE));

export const useGame4Store = createSelectors(useGame4StoreBase);

export const selectItemsFoundGame4 = (): Behavior[] => {
    return useGame4StoreBase.getState().itemsFound;
};

export const selectItemsSelectedGame4 = (): Game4Action => {
    return useGame4StoreBase.getState().itemsSelected;
};

export const selectCarouselValues = (): CarouselValues => {
    return useGame4StoreBase.getState().carouselValues;
};

export const selectListBehaviors = (): Behavior[] => {
    return useGame4StoreBase.getState().listBehaviors;
};

export const setItemsSelectedGame4 = (data: Game4ItemsSelected): void => {
    useGame4StoreBase.setState((state) => ({
        itemsSelected: { ...state.itemsSelected, [data.itemKey]: data.item },
    }));
};

//maybe create a setListBehaviorsFound function instead of doing both in this one
export const setItemsFoundGame4 = (behavior: Behavior): void => {
    const newItemsFound = deepCopy(useGame4StoreBase.getState().itemsFound);
    newItemsFound.splice(behavior.id - 1, 1, behavior);

    useGame4StoreBase.setState((state) => ({
        itemsFound: newItemsFound,
        listBehaviors: state.listBehaviors.map((b) => {
            if (b.emotionId === behavior.id) {
                b.behaviorFound = true;
            }
            return b;
        }),
    }));
};

export const setCarouselValues = (carousel: Carousel): void => {
    useGame4StoreBase.setState((state) => ({
        carouselValues: { ...state.carouselValues, [carousel.key]: carousel.value },
    }));
};

//odd to only reset  the code prop of the itemsSelected
export const resetItemsSelectedGame4 = (): void => {
    useGame4StoreBase.setState((state) => ({
        itemsSelected: { ...state.itemsSelected, code: '' },
    }));
};

//name is odd given it resets everything but the headerContent
export const resetItemsFoundGame4 = (): void => {
    useGame4StoreBase.setState((state) => ({
        ...deepCopy(INITIAL_GAME4_STORE),
        headerContent: state.headerContent,
    }));
};
