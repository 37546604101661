import styles from '@/features/devices/tv/components/games/game4/carouselCharacter.module.scss';
import { useGameStore } from '@/store/gameStore';
import { useGame4Store } from '@/store/games/game4Store';
import { AnimatePresence, motion } from 'framer-motion';

const CarouselCharacter: React.FC = () => {
    const { rightIndex, centerIndex, leftIndex, flowDirection } =
        useGame4Store.use.carouselValues();

    const listBehaviors = useGame4Store.use.listBehaviors();
    const gameResult = useGameStore.use.gameResult();

    const handleFilterCenterIndexCharacter = () => {
        switch (gameResult) {
            case '':
                return listBehaviors[centerIndex].behaviorFound
                    ? 'grayscale(100%)'
                    : 'brightness(100%)';
            case 'success':
                return 'drop-shadow(rgb(41, 255, 152) 0px 0px 37px)';
            case 'error':
                return 'drop-shadow(rgb(255, 41, 41) 0px 0px 37px)';
            default:
                return 'brightness(100%)';
        }
    };

    const commonVariant = {
        opacity: 1,
        transition: {
            type: 'linear',
            duration: 0.5,
        },
    };

    const variants = {
        center: {
            ...commonVariant,
            x: 0,
            y: 0,
            scale: 1.2,
            zIndex: '4',
            filter: handleFilterCenterIndexCharacter(),
            backgroundImage: `url(${listBehaviors[centerIndex].image})`,
        },
        left: {
            ...commonVariant,
            x: '-25rem',
            y: '5rem',
            scale: 0.7,
            zIndex: '3',
            filter: `${
                listBehaviors[leftIndex].behaviorFound
                    ? 'grayscale(100%)'
                    : 'brightness(40%)'
            }`,
            backgroundImage: `url(${listBehaviors[leftIndex].image})`,
        },
        right: {
            ...commonVariant,
            x: '25rem',
            y: '5rem',
            scale: 0.7,
            zIndex: '2',
            filter: `${
                listBehaviors[rightIndex].behaviorFound
                    ? 'grayscale(100%)'
                    : 'brightness(40%)'
            }`,
            backgroundImage: `url(${listBehaviors[rightIndex].image})`,
        },
        rightHidden: {
            x: '20rem',
            scale: 0,
            opacity: 0,
        },
        leftHidden: {
            x: '-20rem',
            scale: 0,
            opacity: 0,
        },
    };

    return (
        <motion.div className={styles.carouselWrapper}>
            <motion.div className={styles.carouselContent}>
                <AnimatePresence initial={false}>
                    <motion.div
                        key={`left-${leftIndex}`}
                        variants={variants}
                        initial={flowDirection ? 'center' : 'leftHidden'}
                        animate="left"
                        exit={'leftHidden'}
                        className={styles.carouselItem}
                    />
                    <motion.div
                        key={`center-${centerIndex}`}
                        variants={variants}
                        initial={flowDirection ? 'right' : 'left'}
                        animate="center"
                        className={styles.carouselItem}
                    />
                    <motion.div
                        key={`right-${rightIndex}`}
                        variants={variants}
                        initial={flowDirection ? 'rightHidden' : 'center'}
                        animate="right"
                        exit={'rightHidden'}
                        className={styles.carouselItem}
                    />
                </AnimatePresence>
            </motion.div>
        </motion.div>
    );
};

export default CarouselCharacter;
