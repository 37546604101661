import { handleInitGameDevMode } from '@/services/global/globalHandleFunctions';
import { isDevelopperMode } from '@/services/global/globalUtils';
import { setRoomName, useRoomStore } from '@/store/roomStore';
import { useEffect } from 'react';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';

export function useDeveloperMode(): void {
    const roomName = useRoomStore.use.roomName();

    useEffect(() => {
        const currentDevice = window.location.pathname
            .split('/')[1]
            .split('-')[0] as DeviceColorWithTvAndMediator;
        //checking current device to avoid sending an empty device to the server and crash it.
        if (!isDevelopperMode || (currentDevice as string) === '') return;

        setRoomName('devRoom');
        handleInitGameDevMode(
            currentDevice as DeviceColorWithTvAndMediator,
            roomName
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomName]);
}
