import type { OverlayDescription, OverlayType } from '@/types/global/types';
import { handleTabletStatus } from '../device/deviceHandleFunctions';
import { emitSocketEvent } from '../global/globalUtils';

export const handleUpdateOverlayIndex = (overlayIndex: number): void => {
    emitSocketEvent('send_update_overlay_index', { overlayIndex });
};

export const handleUpdateOverlayDescriptions = (
    overlayDescriptions: OverlayDescription[],
    overlayType: OverlayType
): void => {
    emitSocketEvent('send_update_overlay_descriptions', {
        overlayDescriptions,
        overlayType,
    });
};

export const handleStartTetris = (): void => {
    emitSocketEvent('send_update_status_tetris', {
        statusTetris: 'start',
    });
    handleUpdateOverlayDescriptions([], 'neutral');
    handleTabletStatus({
        blue: true,
        green: true,
        orange: true,
        red: true,
    });
};
