import MediatorAction from '@/features/devices/tablets/mediator/MediatorAction';
import StatusIndicator from '@/features/devices/tablets/mediator/StatusIndicator';
import styles from '@/features/devices/tablets/mediator/mediatorScreen.module.scss';
import { useState } from 'react';

import RenderComponentGamesActions from '@/features/devices/tablets/mediator/gamesComponents/RenderComponentGamesActions';
import ToggleTabletStatus from '@/features/devices/tablets/mediator/gamesComponents/ToggleTabletStatus';
import datas from '@/services/datas/mediator/actionsGames.json';
import {
    displayPreventMessage,
    displayUnlockCode,
    gamesAccess,
    resetGame,
} from '@/services/datas/mediator/genericDatas.json';

import SocketService from '@/services/SocketService';
import { setDeviceConnectedStatus, useDeviceStore } from '@/store/deviceStore';
import { useGameStore } from '@/store/gameStore';
import { useOverlayStore } from '@/store/overlayStore';
import {
    getNextGameStatus,
    handleGoToGameStatus,
    handleNextOrPreviousOverlayDescription,
} from '@/services/device/deviceHandleFunctions';
import {
    handleTextCurrentGameStatus,
    type ActionsGamesMapping,
} from '@/services/device/mediatorHandleFunctions';
import { handleReset } from '@/services/global/globalHandleFunctions';
import useReSynchronizeDevice from '@/services/hooks/useReSynchronizeDevice';
import useReconnection from '@/services/hooks/useReconnection';
import { useEffect } from 'react';
import { GAMESTATUS, type GameStatus } from '@/types/games/types';
import type { DeviceColorWithTvAndMediator } from '@/types/global/types';
import { emitSocketEvent, objectKeys } from '@/services/global/globalUtils';

const MediatorScreen: React.FC = () => {
    useReconnection();

    const socket = SocketService.getInstance().getSocket();
    const gameStatus = useGameStore.use.gameStatus();

    const allDevices = useDeviceStore.use.deviceStatus();
    const overlayInformations = useOverlayStore.use.descriptions();
    const currentOverlayIndex = useOverlayStore.use.currentOverlayIndex();

    useReSynchronizeDevice('mediator');

    const actionsGames: ActionsGamesMapping = datas;
    const btnActions = actionsGames[gameStatus].actions;

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handlePopUpMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        socket.on('receive_rejoin_room', (data) => {
            setDeviceConnectedStatus(data.localDevice, false);
        });
    }, [socket]);

    const isGameAction =
        gameStatus === GAMESTATUS.GAME1ACTION1 ||
        gameStatus === GAMESTATUS.GAME1ACTION2 ||
        gameStatus === GAMESTATUS.GAME2ACTION ||
        gameStatus === GAMESTATUS.GAME3ACTION ||
        gameStatus === GAMESTATUS.GAME4ACTION ||
        gameStatus === GAMESTATUS.GAME5ACTION;

    const isIntroductionGames =
        gameStatus === GAMESTATUS.INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME1ACTION1INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME1ACTION2INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME2INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME3INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME4INTRODUCTION ||
        gameStatus === GAMESTATUS.GAME5INTRODUCTION;

    const isPreventionMessage =
        gameStatus === GAMESTATUS.GAME1ACTION2PREVENTION ||
        gameStatus === GAMESTATUS.GAME2PREVENTION ||
        gameStatus === GAMESTATUS.GAME3PREVENTION ||
        gameStatus === GAMESTATUS.GAME4PREVENTION ||
        gameStatus === GAMESTATUS.GAME5PREVENTION;

    const isUnlockPage =
        gameStatus === GAMESTATUS.GAME1UNLOCK ||
        gameStatus === GAMESTATUS.GAME2UNLOCK ||
        gameStatus === GAMESTATUS.GAME3UNLOCK ||
        gameStatus === GAMESTATUS.GAME4UNLOCK ||
        gameStatus === GAMESTATUS.GAME5UNLOCK;

    const handleClick = (action: string | undefined) => {
        switch (action) {
            case 'resetGame':
                handleReset();
                break;
            case 'previous':
            case 'next':
                handleNextOrPreviousOverlayDescription(
                    action,
                    gameStatus,
                    overlayInformations,
                    currentOverlayIndex
                );
                break;
            case 'displayUnlockCode':
                handleGoToGameStatus(
                    actionsGames?.[gameStatus]?.unlockCodeStatus as GameStatus
                );
                break;
            case 'nextGameStatus':
                handleGoToGameStatus(getNextGameStatus(gameStatus));
                break;
            case 'displayPreventMessage':
                handleGoToGameStatus(
                    actionsGames?.[gameStatus]?.preventStatus as GameStatus
                );
        }
    };

    const handleReconnection = (device: DeviceColorWithTvAndMediator) => {
        emitSocketEvent('send_synchronize_device', {
            deviceColor: device,
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Écran Médiateur</h1>
                <button
                    className={
                        isMenuOpen
                            ? styles.menuIndicatorOpen
                            : styles.menuIndicatorClosed
                    }
                    onClick={() => handlePopUpMenu()}
                />
            </div>
            <div>
                <div>
                    <div className={styles.subtitles}>
                        <h2>Session en cours</h2>
                        <h3>Description de la session</h3>
                    </div>
                    <div className={styles.devicesContainer}>
                        {objectKeys(allDevices)
                            .filter((deviceType) => deviceType !== 'mediator')
                            .map((deviceType) => {
                                const deviceContent = allDevices[deviceType];
                                return (
                                    <div
                                        key={deviceType}
                                        className={`${styles.device} `}
                                    >
                                        <div
                                            className={styles.tablet}
                                            style={{ borderColor: deviceType }}
                                        >
                                            <p>
                                                {deviceContent.isConnected
                                                    ? '✅'
                                                    : '❌'}
                                            </p>
                                        </div>
                                        <span>{deviceContent.label}</span>
                                        <StatusIndicator
                                            status={deviceContent.isConnected}
                                        />
                                        <button
                                            className={styles.button}
                                            onClick={() =>
                                                handleReconnection(deviceType)
                                            }
                                        >
                                            Resynchroniser
                                        </button>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className={styles.separator}></div>
                <div className={styles.mediatorActions}>
                    <h1>Actions Générales</h1>
                    <h4>Etat du jeu: {gameStatus}</h4>

                    {gameStatus !== GAMESTATUS.WELCOME_SCREEN && (
                        <h2>
                            En cours : {handleTextCurrentGameStatus(gameStatus)}
                        </h2>
                    )}

                    {/* ****************   BUTTONS COMMONS TO ALL GAMES   ************** */}
                    {/* resetGame, initGame, next/prev overlay, display prevent message and unlock Code  */}
                    <MediatorAction
                        handleFunction={() => handleClick(resetGame.nameFunction)}
                        title={resetGame.btnTitle}
                        description={resetGame.description}
                        displayingCondition={true}
                    />
                    <MediatorAction
                        handleFunction={() =>
                            handleClick(btnActions?.nextGameStatus.nameFunctions[0])
                        }
                        title={btnActions?.nextGameStatus?.btnTitle || ''}
                        description={btnActions?.nextGameStatus?.description || ''}
                        displayingCondition={
                            gameStatus === GAMESTATUS.WELCOME_SCREEN
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick(btnActions?.nextMessage?.nameFunctions[0]);
                        }}
                        title={btnActions?.nextMessage?.btnTitle || ''}
                        description={btnActions?.nextMessage?.description}
                        displayingCondition={
                            isIntroductionGames || isPreventionMessage
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick(btnActions?.prevMessage?.nameFunctions[0]);
                        }}
                        title={btnActions?.prevMessage?.btnTitle || ''}
                        description={btnActions?.prevMessage?.description}
                        displayingCondition={
                            (isIntroductionGames || isPreventionMessage) &&
                            currentOverlayIndex > 0
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick(displayPreventMessage?.nameFunction);
                        }}
                        title={displayPreventMessage.btnTitle}
                        description={displayPreventMessage.description}
                        displayingCondition={
                            gameStatus !== GAMESTATUS.WELCOME_SCREEN &&
                            gameStatus !== GAMESTATUS.INTRODUCTION &&
                            !isPreventionMessage &&
                            gameStatus !== GAMESTATUS.FINISHED
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick(displayUnlockCode?.nameFunctions);
                        }}
                        title={displayUnlockCode?.btnTitle}
                        description={displayUnlockCode?.description}
                        displayingCondition={
                            gameStatus !== GAMESTATUS.WELCOME_SCREEN &&
                            gameStatus !== GAMESTATUS.INTRODUCTION &&
                            gameStatus !== GAMESTATUS.FINISHED &&
                            !isUnlockPage
                        }
                    />
                    <MediatorAction
                        handleFunction={() => {
                            handleClick(
                                btnActions?.nextGameStatus?.nameFunctions[0]
                            );
                        }}
                        title={btnActions?.nextGameStatus?.btnTitle || ''}
                        description={btnActions?.nextGameStatus?.description}
                        displayingCondition={isUnlockPage}
                    />

                    {/* ****************  ACTIONS BUTTONS FOR CURRENT GAME   ************** */}
                    {/* select good combinations, validate , and pass success or error messages,
                    buttons for remote tetris and carousel to change directions ************** */}
                    <div className={styles.separator}></div>
                    <h1>Accès jeux</h1>
                    {isGameAction && <RenderComponentGamesActions />}

                    {/* ****************  BUTTONS TO ACCESS GAMES ANYTIME  ************** */}
                    {gamesAccess.map((action, index) => (
                        <MediatorAction
                            key={index}
                            handleFunction={() => {
                                handleGoToGameStatus(
                                    action.accessGameStatus as GameStatus
                                );
                            }}
                            title={action.btnTitle}
                            description={action.description}
                            displayingCondition={true}
                        />
                    ))}

                    {/* **************** DESACTIVATE / ACTIVATE TABLETS ************** */}
                    <div className={styles.separator}></div>
                    <h1>Désactivation / Activation Tablettes</h1>
                    <ToggleTabletStatus />
                </div>
            </div>
        </div>
    );
};

export default MediatorScreen;
